import React, { createContext, useContext } from 'react';
import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL, API_KEY } from '../config/config';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './authContext';

const ThreadContext = createContext(null);

const queryClient = new QueryClient();

const fetchThread = async ({ queryKey }) => {
	const [_, { threadId, visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/threads/${threadId}?with_first_post=true`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchRelatedThreads = async ({ pageParam = 1, queryKey }) => {
	const [_, { threadId, visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/threads/${threadId}/related-threads?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const ThreadProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);
	const location = useLocation();

	const urlPattern = /^\/([\w-]+)\.t(\d+)\/?$/;
	const match = location.pathname.match(urlPattern);
	const threadId = match ? match[2] : null;

	const { data: threadData, isLoading: threadLoading } = useQuery({
		queryKey: ['thread', { threadId, visitor }],
		queryFn: fetchThread,
		enabled: !!threadId && !!visitor,
	});

	const {
		data: relatedThreadsData,
		fetchNextPage,
		hasNextPage,
		isFetching: relatedThreadsLoading,
	} = useInfiniteQuery({
		queryKey: ['relatedThreads', { threadId, visitor }],
		queryFn: fetchRelatedThreads,
		getNextPageParam: (lastPage) => {
			return lastPage.pagination.current_page < lastPage.pagination.last_page
				? lastPage.pagination.current_page + 1
				: undefined;
		},
		enabled: !!threadId && !!visitor,
	});

	const thread = threadData?.thread || null;
	const firstPost = threadData?.first_post || null;
	const relatedThreads = relatedThreadsData?.pages.flatMap((page) => page.threads) || [];

	return (
		<ThreadContext.Provider
			value={{
				thread,
				firstPost,
				relatedThreads,
				relatedThreadsLoading,
				hasMoreRelatedThreads: hasNextPage,
				loadMoreRelatedThreads: fetchNextPage,
			}}
		>
			{children}
		</ThreadContext.Provider>
	);
};

const ThreadContextProvider = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<ThreadProvider>{children}</ThreadProvider>
		</QueryClientProvider>
	);
};

export { ThreadContext, ThreadContextProvider as ThreadProvider };
