import React, { useContext, useState } from "react";
import { ForumContext, ForumProvider } from "../../context/forumContext";
import Body from "../common/Body";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { generateHeadTags } from "react-seo-tools";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../common/Breadcrumb";

import EditorComponent from "../Editor/EditorComponent";
import blockJsonToBbCode from "../Editor/BlockJsonToBbCode";
import LoadingContent from "../common/LoadingContent";
import {boardUrl} from "../../config/config";
import SeoAnalyzer from "./section/SeoAnalyzer";
import CustomFields from "./section/CustomFields";
import customFields from "./section/CustomFields";

const MainContent = () => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();

	const { forum, forumLoading, postThreadMutation, attachmentKey } = useContext(ForumContext);

	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');

	const [seoData, setSeoData] = useState({});
	const [customFieldsData, setCustomFieldsData] = useState({});

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (forum) {
			const forumId = forum.node_id;
			const formattedCustomFields = Object.entries(customFieldsData);

			const result = await postThreadMutation.mutateAsync({ forumId, title, message, seoData, customFieldsData: formattedCustomFields, attachmentKey });
			if (result) {
				navigate(result.thread.view_url);
			}
		}
	};

	const handleEditorChange = (value) => {
		setMessage(blockJsonToBbCode(value));
	}

	const handleSeoChange = (e) => {
		setSeoData(e);
	}

	const handleCustomFieldsChange = (e) => {
		setCustomFieldsData(e);
	}

	return (
		<>
			{forum && (
				<Helmet>
					{generateHeadTags({
						title: `${t('post_new_thread_in')} ${forum.title}`,
						description: forum.description,
						openGraph: {
							type: 'website',
							title: forum.title,
						},
					})}
				</Helmet>
			)}

			{forumLoading && <LoadingContent />}

			{forum && (
				<>
					<section className="mb-4">
						<Breadcrumb items={forum.breadcrumbs}/>
					</section>

					<section className="mb-4">
						<h1 className="text-2xl font-bold mb-4">{t('post_new_thread')}</h1>
						<form onSubmit={handleSubmit} className="space-y-4">
							<label htmlFor="title" className="block text-sm font-medium text-gray-700">
								{t('title')}
							</label>

							<input
								type="text"
								id="title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								required
								className="mt-1 block w-full border border-gray-300 rounded-md p-2 outline-0 focus:ring focus:ring-opacity-50 focus:ring-red-500"
							/>

							<label htmlFor="message" className="block text-sm font-medium text-gray-700">
								{t('content')}
							</label>

							{attachmentKey &&
								<EditorComponent
									attachmentKey={attachmentKey}
									onMessageChange={handleEditorChange}
								/>
							}

							<CustomFields onCustomFieldValueChange={handleCustomFieldsChange} />

							{forum.type_data.can_set_thread_meta_fields && (
								<SeoAnalyzer
									url={`${boardUrl} > threads`}
									onSeoValueChange={handleSeoChange}
								/>
							)}

							{postThreadMutation.isLoading && <LoadingContent/>}

							{postThreadMutation.isError &&
								<p className="text-red-500">{postThreadMutation.error.message}</p>
							}

							<button
								type="submit"
								className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition duration-300"
							>
								{t('post_new_thread')}
							</button>
						</form>
					</section>
				</>
				)}
		</>
	);
};

const ForumPostThread = () => {
	return (
		<ForumProvider>
			<Body mainContent={<MainContent />} sidebarContent={null} />
		</ForumProvider>
	);
};

export default ForumPostThread;
