import {reaction, VALIDATED_USER_GROUP_ID} from "../config/config";

export const isValidatedUser = (userSecondaryGroups) => {
	return Array.isArray(userSecondaryGroups) && userSecondaryGroups.includes(VALIDATED_USER_GROUP_ID);
}

export const convertReactionUsersToReactionData = (reactionUsers) => {
	let reactionData = [];
	reactionUsers.map(reactionUser => {
		reactionData.push({
			node: reaction(reactionUser.reaction_id).node,
			label: reaction(reactionUser.reaction_id).label,
			by: reactionUser.username
		})
	})

	return reactionData;
}