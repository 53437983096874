import React from 'react';
import {format, formatDistanceToNow} from 'date-fns';
import { vi } from 'date-fns/locale';

const RelativeTime = ({timestamp}) => {
	const date = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript Date object
	const now = new Date();
	const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));

	let displayTime;
	if (date > oneWeekAgo) {
		displayTime = formatDistanceToNow(date, { addSuffix: true, locale: vi });
	} else {
		const monthNames = [
			'tháng Một', 'tháng Hai', 'tháng Ba', 'tháng Tư', 'tháng Năm', 'tháng Sáu',
			'tháng Bảy', 'tháng Tám', 'tháng Chín', 'tháng Mười', 'tháng Mười Một', 'tháng Mười Hai'
		];
		const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		const month = monthNames[date.getMonth()];
		if (date.getFullYear() === new Date().getFullYear()) {
			displayTime = `${day} ${month}`;
		} else {
			displayTime = format(date, 'dd/MM/yyyy');
		}
	}

	return <span className="inline-block first-letter:uppercase">{displayTime}</span>;
}

export default RelativeTime;