import Table from '@editorjs/table';
import Embed from "./extensions/EmbedTool";
import BreakLineTool from "./extensions/BreakLineTool";
import List from '@editorjs/list';
import Code from '@editorjs/code';
import Header from '@editorjs/header';
import QuoteTool from "./extensions/QuoteTool";
import Paragraph from "@editorjs/paragraph";
import ImageTool from "@editorjs/image";
import AttachesTool from '@editorjs/attaches';
import InlineCode from '@editorjs/inline-code';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';

import {API_BASE_URL, API_KEY} from "../../config/config";

export const EDITOR_JS_TOOLS = ({visitor, attachmentKey}) => {
	const userId = visitor?.user_id || 0; // Fallback to 0 for guests

	return {
		alignmentTune: {
			class: AlignmentTuneTool,
			config: {
				default: "left",
				blocks: {
					header: "left",
				}
			}
		},

		paragraph: {
			class: Paragraph,
			inlineToolbar: true,
			config: {
				placeholder: "Start typing your text...",
				preserveBlank: true,
			},
			shortcut: 'CMD+SHIFT+P',
			tunes: ['alignmentTune']
		},

		header: {
			class: Header,
			inlineToolbar: true,
			config: {
				placeholder: "Enter a header"
			},
			shortcut: 'CMD+SHIFT+H'
		},
		quote: {
			class: QuoteTool,
			inlineToolbar: true,
			config: {
				placeholder: "Enter a quote"
			},
			shortcut: 'CMD+SHIFT+Q'
		},

		embed: {
			class: Embed,
			inlineToolbar: true,
			config: {
				services: {
					facebook: true,
					youtube: true,
				}
			},
			shortcut: 'CMD+SHIFT+E',

		},

		table: {
			class: Table,
			inlineToolbar: true,
			shortcut: 'CMD+SHIFT+T'
		},

		list: {
			class: List,
			inlineToolbar: true,
			shortcut: 'CMD+SHIFT+L'
		},

		code: {
			class: Code,
			inlineToolbar: true,
			shortcut: 'CMD+SHIFT+C'
		},

		image: {
			class: ImageTool,
			config: {
				endpoints: {
					byUrl: ''
				},

				uploader: {
					async uploadByFile(file) {
						const formData = new FormData();
						formData.append('attachment', file);
						formData.append('key', attachmentKey);

						try {
							const response = await fetch(`${API_BASE_URL}/attachments/`, {
								method: 'POST',
								headers: {
									'XF-Api-Key': API_KEY,
									'XF-Api-User': userId,
								},
								body: formData,
							});

							const data = await response.json();

							if (data.attachment) {
								const attachment = data.attachment;

								return {
									success: 1,
									file: {
										url: attachment.direct_url,
										isAttachment: true,
										attachmentId: attachment.attachment_id,
									},
								};
							} else {
								console.log(data);
								throw new Error('Failed to upload the image');
							}
						} catch (error) {
							console.error('Error uploading image:', error);
							return {
								success: 0,
								file: {
									url: ''
								}
							};
						}
					},

					async uploadByUrl(url) {
						return {
							success: 1,
							file: {
								url: url,
							}
						}
					}
				},
			},
			inlineToolbar: true,
			shortcut: 'CMD+SHIFT+I'
		},

		inlineCode: InlineCode,

		attaches: {
			class: AttachesTool,
			config: {
				uploader: {
					async uploadByFile(file) {
						const formData = new FormData();
						formData.append('attachment', file);
						formData.append('key', attachmentKey);

						try {
							const response = await fetch(`${API_BASE_URL}/attachments/`, {
								method: 'POST',
								headers: {
									'XF-Api-Key': API_KEY,
									'XF-Api-User': userId,
								},
								body: formData,
							});

							const data = await response.json();

							if (data.attachment) {
								const attachment = data.attachment;

								return {
									success: 1,
									file: {
										url: attachment.direct_url,
										size: attachment.file_size,
										name: attachment.filename,
										title: attachment.filename,
										id: attachment.attachment_id
									},
									title: attachment.filename,
								};
							} else {
								throw new Error('Failed to upload the file');
							}
						} catch (error) {
							console.error('Error uploading file:', error);
							return {
								success: 0,
								file: {
									url: '',
									size: 0,
									name: '',
									id: 0
								}
							};
						}
					},
				}
			},
			inlineToolbar: true,
			shortcut: 'CMD+SHIFT+A'
		},

		breakLine: {
			class: BreakLineTool,
			inlineToolbar: true,
			shortcut: 'CMD+SHIFT+ENTER',
		},
	};
};