import React from "react";

const SectionHeader = ({title, icon, url = null}) => {
	return <div className="text-xl font-bold mb-2 border-b-none md:border-b-4 md:border-primary">
		<div className="bg-primary text-white block px-4 pt-2 pb-2 md:inline-block md:pb-0 md:rounded-t-lg">
			{icon}
			<a href={url ? url : '#'}>{title}</a>
		</div>
	</div>;
}

export default SectionHeader;