import React, {useContext, useEffect} from 'react';

import Body from "../common/Body";
import {HomeProvider} from "../../context/homeContext";
import FeaturedPostsSection from "./section/FeaturedPostsSection";
import MainstreamPostsSection from "./section/MainstreamPostsSection";
import TrendingsSection from "./section/TrendingsSection";
import VideoSection from "./section/VideoSection";
import DiscussionSection from "./section/DiscussionSection";
import SuggestedSection from "./section/SuggestedSection";
import {AuthContext} from "../../context/authContext";
import FloatingButtonSection from "../Forum/section/FloatingButtonSection";
import {isValidatedUser} from "../../utils/user";
import {isMobile} from "../../utils/utils";

const TITLE = 'GOLIVE.VN - THÔNG TIN GIẢI TRÍ DÀNH CHO GIỚI TRẺ';
const DESCRIPTION = 'Diễn Đàn Chia Sẻ Thông Tin Nghệ Thuật và Giải trí';

const MainContent = () => {
	return (
		<div>
			<section className="mb-4 md:mb-8">
				<FeaturedPostsSection/>
			</section>

			<section className="-mx-4 mb-4 md:mb-8 md:-mx-0">
				<SuggestedSection/>
			</section>

			<section className="mb-4 md:mb-8">
				<MainstreamPostsSection/>
			</section>

			<section className="mb-4 md:mb-8">
				<TrendingsSection/>
			</section>

			<section className="-mx-4 mb-4 md:mb-8 md:-mx-0">
				<VideoSection/>
			</section>

			{!isMobile() && (
				<section className="mb-4 md:mb-8">
					<DiscussionSection/>
				</section>
			)}
		</div>
	);
}

const Homepage = () => {
	useEffect(() => {
		document.title = TITLE;
		document.querySelector('meta[name="description"]').setAttribute("content", DESCRIPTION);
	}, []);

	const {visitor} = useContext(AuthContext);

	return (
		<HomeProvider>
			{(visitor && visitor.user_id && isValidatedUser(Object.values(visitor.secondary_group_ids))) ? <FloatingButtonSection /> : null}

			<Body mainContent={<MainContent/>} sidebarContent={'default'} />
		</HomeProvider>
	);
};

export default Homepage;
