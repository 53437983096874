import {PostContext, PostProvider} from "../../context/postContext";
import Body from "../common/Body";
import {useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import blockJsonToBbCode from "../Editor/BlockJsonToBbCode";
import {Helmet} from "react-helmet";
import {generateHeadTags} from "react-seo-tools";
import LoadingNoMoreContent from "../common/LoadingNoMoreContent";
import Breadcrumb from "../common/Breadcrumb";
import EditorComponent from "../Editor/EditorComponent";
import LoadingContent from "../common/LoadingContent";

const MainContent = () => {
	const { i18n, t } = useTranslation();
	const {post, thread, submitPostMutation, attachmentKey} = useContext(PostContext);
	const [message, setMessage] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (post) {
			const postId = post.post_id;
			const result = await submitPostMutation.mutateAsync({postId, message, attachmentKey});
			if (result) {
				navigate(result.post.thread_view_url);
			}
		}
	}

	const handleChange = (value) => {
		setMessage(blockJsonToBbCode(value));
	}

	return (
		<>
			{thread && (
				<Helmet>
					{generateHeadTags({
						title: `${t('edit_post')}`,
						description: '',
						openGraph: {
							type: 'website',
							title: thread.title,
						},
					})}
				</Helmet>
			)}

			{post && thread ? (
				<>
					<section className="mb-4">
						<Breadcrumb items={thread.breadcrumbs}/>
					</section>

					<section className="mb-4">
						<h1 className="text-2xl font-bold mb-4">{t('edit_post')}</h1>
						<form onSubmit={handleSubmit} className="space-y-4">
							{attachmentKey &&
								(<EditorComponent
									initialMessage={post.message_block_json_parsed}
									attachmentKey={attachmentKey}
									onMessageChange={handleChange}
								/>)
							}

							{submitPostMutation.isLoading && <LoadingContent />}

							{submitPostMutation.isError &&
								<p className="text-red-500">{submitPostMutation.error.message}</p>}

							<button
								type="submit"
								className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition duration-300"
							>
								{t('submit')}
							</button>
						</form>
					</section>
				</>
			) : (
				<LoadingNoMoreContent/>
			)}
		</>
	);
}

const PostEdit = () => {
	return <PostProvider>
		<Body mainContent={<MainContent/>} sidebarContent={null}/>
	</PostProvider>
}

export default PostEdit;