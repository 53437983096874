import React, {useContext, useEffect} from 'react';

import { useTranslation } from "react-i18next";

import { AuthContext } from '../../context/authContext';
import { TagContext } from '../../context/tagContext';

import {boardUrl, logoUrl} from '../../config/config';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faBars,
	faChartLine, faChevronDown, faChevronUp,
	faCogs,
	faInfoCircle, faSearch,
	faSignOut,
	faUser
} from "@fortawesome/free-solid-svg-icons";

import {MenuItem, MenuDivider} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import PopupMenu from "./PopupMenu";
import SearchBar from "./SearchBar";
import {HeaderContext} from "../../context/headerContext";
import CategoryList from "./CategoryList";
import LatestNews from "./LatestNews";
import {useLocation, useNavigate} from "react-router-dom";

const FeaturedTagList = ({tags}) => {
	const navigate = useNavigate();

	return (
		<div
			className="flex flex-col justify-center items-center py-4 px-4 sm:px-10 bg-primary text-white min-h-[30px]">
			<div
				className='container mx-auto block items-center justify-center overflow-x-auto gap-4 w-full whitespace-nowrap md:flex lg:max-w-[1150px]'>
				<FontAwesomeIcon icon={faChartLine} className="inline-block mr-2 md:mr-0"/>

				{tags.map((tag, index) => (
					(
						<li key={index} className="list-none inline-block whitespace-nowrap mr-2 md:mr-0">
							<span onClick={() => navigate(`/tags/${tag.tag_url}`)}
							   className="inline-block bg-white text-[14px] text-primary px-2 py-1 rounded-lg cursor-pointer hover:text-red-900">
								{tag.tag}
							</span>
						</li>
					)
				))}
			</div>
		</div>
	);
}

const AccountMenu = ({user}) => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();

	return (
		<>
			<MenuItem onClick={() => navigate(user.view_url)}>
				<FontAwesomeIcon icon={faUser} className="mr-2"/> {t('profile_page')}
			</MenuItem>

			<MenuItem href={`${boardUrl}/index.php?account`}>
				<FontAwesomeIcon icon={faInfoCircle} className="mr-2"/> {t('account_info')}
			</MenuItem>

			<MenuItem href={`${boardUrl}/index.php?account/security`}>
				<FontAwesomeIcon icon={faCogs} className="mr-2"/> {t('settings')}
			</MenuItem>

			<MenuDivider />

			<MenuItem href={`${boardUrl}/logout`}>
				<FontAwesomeIcon icon={faSignOut} className="mr-2"/> {t('logout')}
			</MenuItem>
		</>
	)
}

const Header = () => {
	const {visitor} = useContext(AuthContext);

	const {featuredTags} = useContext(TagContext);

	const {
		toggleCategoryListPopup, isCategoryListPopupActive,
		isLatestNewsSectionActive, toggleLatestNewsSectionPopup, closeLatestNewsSectionPopup
	} = useContext(HeaderContext);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		closeLatestNewsSectionPopup();
	}, [location]);

	const { i18n, t } = useTranslation();

	return (
		<>
			{!isCategoryListPopupActive && (
				<header className="shadow-lg font-[sans-serif] tracking-wide relative z-50">
					<div className="bg-white py-4 px-2 md:px-4">
						<div className="container mx-auto flex items-center justify-between gap-4 w-full lg:max-w-[1150px]">
							<button onClick={() => navigate('/')} className="flex flex-row md:w-auto md:block">
								<img src={logoUrl} alt="logo" className='w-24 lg:w-36'/>
							</button>

							<div className='hidden w-full order-2 lg:flex lg:ml-auto lg:w-[250px] lg:order-[unset]'>
								<SearchBar/>
							</div>

							<div onClick={toggleLatestNewsSectionPopup}
								className="ml-auto mr-auto inline-flex items-center gap-x-2 text-gray-500 cursor-pointer lg:hidden">
								<span className="text-lg">
									{t('latest_news')}
								</span>

								{isLatestNewsSectionActive ? (
									<FontAwesomeIcon icon={faChevronUp} />
								) : (
									<FontAwesomeIcon icon={faChevronDown} />
								)}
							</div>

							<div className='flex items-center ml-auto space-x-6 md:mr-0'>
								{(visitor && visitor.user_id) ? (
									<>
										<PopupMenu label={
											<button
												className="hidden font-semibold text-[15px] border-none outline-none text-primary hover:underline lg:block">
												{t("welcome")}, {visitor.username}
											</button>
										}>
											<AccountMenu user={visitor}/>
										</PopupMenu>

										<button
											className="hidden px-4 py-2 text-sm rounded-sm font-bold text-white border-2 border-primary bg-primary transition-all ease-in-out duration-300 hover:bg-transparent hover:text-primary lg:block">
											<a href={`${boardUrl}/logout/`}>{t("logout")}</a>
										</button>

										<div className="flex items-center gap-x-1 lg:hidden">
											<button onClick={() => navigate('/search')}
											      className="flex w-[32px] h-[32px] items-center justify-center rounded-full bg-primary text-white cursor-pointer hover:bg-red-900">
													<FontAwesomeIcon icon={faSearch}/>
											</button>

											<PopupMenu label={
												<span
													className="flex w-[32px] h-[32px] items-center justify-center rounded-full bg-primary text-white hover:bg-red-900">
														<FontAwesomeIcon icon={faUser}/>
												</span>
											}>
												<AccountMenu user={visitor}/>
											</PopupMenu>

											<span onClick={toggleCategoryListPopup}
											      className="flex w-[32px] h-[32px] items-center justify-center rounded-full bg-primary text-white cursor-pointer hover:bg-red-900">
													<FontAwesomeIcon icon={faBars}/>
											</span>
										</div>
									</>
								) : (
									<>
										<button onClick={() => navigate('/search')}
										   className="flex w-[32px] h-[32px] items-center justify-center rounded-full bg-primary text-white cursor-pointer hover:bg-red-900 lg:hidden">
											<FontAwesomeIcon icon={faSearch}/>
										</button>

										<button
											className='font-semibold text-[15px] border-none outline-none'>
											<a href={`${boardUrl}/index.php?login&universal=1`}
											   className={`text-primary`}>
												<span className="hidden lg:block">{t("login")}</span>
												<span
													className="flex w-[32px] h-[32px] items-center justify-center rounded-full bg-primary text-white hover:bg-red-900 lg:hidden"><FontAwesomeIcon
													icon={faUser}/></span>
											</a>
										</button>

										<button
											className="hidden px-4 py-2 text-sm rounded-sm font-bold text-white border-2 border-primary bg-primary transition-all ease-in-out duration-300 hover:bg-transparent hover:text-primary lg:block">
											<a href={`${boardUrl}/index.php?register&universal=1`}>{t("register")}</a>
										</button>

										<span onClick={toggleCategoryListPopup}
										      className="inline-flex w-[32px] h-[32px] items-center justify-center rounded-full bg-primary text-white cursor-pointer hover:bg-red-900 lg:hidden">
												<FontAwesomeIcon icon={faBars}/>
										</span>
									</>
								)}
							</div>
						</div>
					</div>

					{(featuredTags && !isLatestNewsSectionActive) && <FeaturedTagList tags={featuredTags}/>}
				</header>
			)}

			{isCategoryListPopupActive && <CategoryList/>}

			{isLatestNewsSectionActive && <LatestNews />}
		</>
	);
};

export default Header;