import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import {boardTitle, logoOgUrl} from "../../../config/config";
import useDebounce, {checkImageExists} from "../../../utils/utils";
import FloatingLabelInput from "../../common/FloatingLabelInput";

const AnalyzerScore = ({contentLength, validate}) => {
	const {t} = useTranslation();
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		switch (validate) {
			case 'title':
				if (contentLength >= 10 && contentLength <= 70) {
					setIsValid(true);
				} else {
					setIsValid(false);
				}
				break;

			case 'description':
				if (contentLength >= 60 && contentLength <= 160) {
					setIsValid(true);
				} else {
					setIsValid(false);
				}
				break;

			default:
				break;
		}
	}, [contentLength, validate]);

	return (
		<div className={`inline-flex items-center px-3 py-1.5 text-sm font-medium text-center rounded-lg ${isValid ? 'text-white bg-green-600' : 'text-primary bg-gray-200'}`}>
			{validate === 'title'
				? `${t('title')}: ${contentLength}/70 ${t('character')}`
				: `${t('description')}: ${contentLength}/160 ${t('character')}`}
		</div>
	);
}

const SeoAnalyzer = ({ title, description, url, onSeoValueChange }) => {
	const { t } = useTranslation();

	const [seoData, setSeoData] = useState({
		seoTitle: title || '',
		seoDescription: description || '',
		seoImageUrl: '',
	});

	const [displayImageUrl, setDisplayImageUrl] = useState(logoOgUrl);

	const titleLength = seoData.seoTitle.length;
	const descriptionLength = seoData.seoDescription.length;

	useDebounce(() => {
		try {
			if (seoData.seoImageUrl !== '' && seoData.seoImageUrl !== 'loading' && checkImageExists(seoData.seoImageUrl)) {
				setDisplayImageUrl(seoData.seoImageUrl);
			} else {
				setDisplayImageUrl(logoOgUrl);
			}
		} catch (e) {
			setDisplayImageUrl(logoOgUrl);
		}
	}, [seoData.seoImageUrl], 800);

	const handleInputChange = (field, value) => {
		setSeoData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	useEffect(() => {
		if (onSeoValueChange) {
			onSeoValueChange(seoData);
		}
	}, [onSeoValueChange, seoData]);

	return (
		<>
			<h1 className="text-2xl font-bold mb-4">{t('seo_options')} ({t('optional')})</h1>

			<div className="seo-analyzer space-y-4 px-4 py-2 shadow-xl rounded-xl md:px-8 md:py-4 md:shadow-2xl md:rounded-2xl">
				<FloatingLabelInput
					inputId="seo-title"
					title={t('title')}
					defaultValue={seoData.seoTitle}
					onChange={(e) => handleInputChange('seoTitle', e.target.value)}
				/>

				<FloatingLabelInput
					inputId="seo-description"
					title={t('description')}
					defaultValue={seoData.seoDescription}
					onChange={(e) => handleInputChange('seoDescription', e.target.value)}
				/>

				<FloatingLabelInput
					inputId="seo-image"
					title={t('open_graph_image_url')}
					defaultValue={seoData.seoImageUrl}
					onChange={(e) => handleInputChange('seoImageUrl', e.target.value)}
				/>

				<label className="block text-sm font-medium text-gray-700">
					{t('preview')}
				</label>

				<div className="google-snippet-preview mt-4 p-4 bg-gray-100 rounded-md max-w-screen-md">
					<div className="before:table after:table after:clear-both">
						<div className="preview-logo float-left">
							<div className="w-[80px] h-[80px] mr-4">
								<img src={displayImageUrl} alt={boardTitle} className="w-full h-full object-cover"/>
							</div>
						</div>

						<div className="preview-content break-words">
							<p className="text-sm text-gray-500">{url}</p>
							<p className="text-xl text-blue-600">{seoData.seoTitle || t('title_here')} | {boardTitle}</p>
							<p className="text-sm text-gray-600">{seoData.seoDescription || t('description_here')}</p>
						</div>
					</div>

					<div className="text-xs mt-4 flex flex-wrap items-center whitespace-nowrap gap-2">
						<AnalyzerScore contentLength={titleLength} validate={'title'}/>
						<AnalyzerScore contentLength={descriptionLength} validate={'description'}/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SeoAnalyzer;
