import {ThreadContext, ThreadProvider} from "../../context/threadContext";
import Body from "../common/Body";
import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {generateHeadTags} from "react-seo-tools";
import Breadcrumb from "../common/Breadcrumb";
import HeaderSection from "./section/HeaderSection";
import HtmlParsedText from "../common/HtmlParsedText";
import FirstPostContentSection from "./section/FirstPostContentSection";
import ActionButtons from "../common/MemberProfilePost/ActionButtons";
import TagListSection from "./section/TagListSection";
import {convertReactionUsersToReactionData} from "../../utils/user";
import {HomeProvider} from "../../context/homeContext";
import SpecialSection from "../Homepage/section/SpecialSection";
import PreloadImage from "../common/PreloadImage";

const HeroContent = () => {
	const { thread, firstPost } = useContext(ThreadContext);

	if (thread) {
		return (
			<>
				<div className="container h-full w-full mx-auto mt-4 px-2 lg:max-w-[1150px] lg:px-0">
					<section className="mb-4">
						<Breadcrumb items={thread.breadcrumbs}/>
					</section>

					<section className="mb-4">
						<HeaderSection thread={thread} firstPost={firstPost}/>
					</section>
				</div>

				{thread.magazine_hero_image_url && (
					<div className="w-full my-4">
						<PreloadImage src={thread.magazine_hero_image_url} alt={thread.title} className="w-full h-auto"/>
					</div>
				)}
			</>
		);
	}
}

const HeroContentBottom = () => {
	return (
		<div className="bg-primary text-white p-4 mb-4">
			<div className="container h-full w-full mx-auto mt-4 lg:max-w-[1150px]">
				<SpecialSection/>
			</div>
		</div>
	);
}

const MainContent = () => {
	const {thread, firstPost, relatedThreads} = useContext(ThreadContext);

	if (thread && firstPost && relatedThreads) {
		return (
			<>
				{(thread && firstPost) && (
					<Helmet>
						{generateHeadTags({
							title: thread.title,
							description: thread.excerpt,
							openGraph: {
								type: 'article',
								title: thread.title,
								image: thread.thumbnail_url,
								'article:author': thread.username,
								'article:tag': thread.tags,
								'article:published_time': thread.post_date_meta,
							},
						})}
					</Helmet>
				)}

				<section className="bg-gray-200 p-4 -mx-4 mb-4 md:-mx-0 md:rounded-lg">
					<HtmlParsedText text={thread.magazine_hero_text_parsed}/>
				</section>

				<section className="post-content mb-4">
					<FirstPostContentSection thread={thread} firstPost={firstPost}/>
				</section>

				<section className="mb-4">
					<ActionButtons
						firstPostId={thread.first_post_id}
						initialReactionsData={convertReactionUsersToReactionData(firstPost.reaction_users)}
						initialReactionScore={Object.values(firstPost.reaction_users).length}
						isReactedTo={firstPost.is_reacted}
						canReact={firstPost.can_react}
						threadViewUrl={thread.view_url}
					/>
				</section>

				<section className="mb-4">
					<TagListSection tags={Object.values(thread.tags)}/>
				</section>
			</>
		);
	}
}

const ThreadMagazine = () => {
	return <ThreadProvider><HomeProvider>
		<Body heroContent={<HeroContent/>}
		      mainContent={<MainContent/>}
		      sidebarContent={null}
		      heroContentBottom={<HeroContentBottom />}
		/>
	</HomeProvider></ThreadProvider>
}

export default ThreadMagazine;