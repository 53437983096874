import Quote from '@editorjs/quote';

export default class QuoteTool extends Quote {
	container = null;

	static get pasteConfig() {
		return {
			tags: ['blockquote']
		};
	}

	render() {
		let container = super.render();
		this.container = container;

		return container;
	}

	onPaste(event){
		switch (event.type){
			case 'tag':
				const quote = event.detail.data.innerText;

				let textEl = this.container.getElementsByClassName(this.CSS.text)[0];
				if (textEl) {
					textEl.innerText = quote;
				}

				break;
		}
	}
}