import React, {useContext} from 'react';
import {ForumContext, ForumProvider} from "../../context/forumContext";
import Body from "../common/Body";
import {generateHeadTags} from "react-seo-tools";
import {Helmet} from "react-helmet";
import Breadcrumb from "../common/Breadcrumb";
import ThreadListSection from "./section/ThreadListSection";
import FloatingButtonSection from "./section/FloatingButtonSection";
import StickySection from "../Category/section/StickySection";
import LoadingContent from "../common/LoadingContent";
import LoadingNoMoreContent from "../common/LoadingNoMoreContent";

const MainContent = () => {
	const { forum, forumLoading, threads, stickyThreads } = useContext(ForumContext);

	return (
		<div>
			{(forum && forum.type_data.can_create_thread) && <FloatingButtonSection forum={forum} />}

			{forum && (
				<Helmet>
					{generateHeadTags({
						title: forum.title,
						description: forum.description,
						openGraph: {
							type: 'website',
							title: forum.title,
						},
					})}
				</Helmet>
			)}

			{forum ? (
				<div>
					<section className="mb-4">
						<Breadcrumb items={forum.breadcrumbs}/>
					</section>

					{!forumLoading ? (
						<div>
							{stickyThreads && (
								<section className="mb-4 md:mb-8">
									<StickySection category={forum} stickyThreads={stickyThreads}/>
								</section>
							)}

							{threads && (
								<section className="mb-4">
									<ThreadListSection forum={forum} initialThreads={threads}/>
								</section>
							)}
						</div>
					) : (
						<LoadingContent />
					)}
				</div>
			) : (
				<LoadingNoMoreContent />
			)}
		</div>
	);
}

const Forum = () => {
	return (
		<ForumProvider>
			<Body mainContent={<MainContent />} sidebarContent={'default'} />
		</ForumProvider>
	);
}

export default Forum;