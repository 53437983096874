import React from "react";

const HtmlParsedText = ({text}) => {
	const messageParsed = {__html:text};

	return (
		<div className="text-justify">
			<div dangerouslySetInnerHTML={messageParsed} />
		</div>
	);
}

export default HtmlParsedText;