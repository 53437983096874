import BreakLine from 'editorjs-break-line';

export default class BreakLineTool extends BreakLine {
	static get pasteConfig() {
		return {
			tags: ['br']
		}
	}

	onPaste(event){
		switch (event.type) {
			case 'tag':
				const tuneName = this.tune.name;
				const status = false;
				this.data = { [tuneName]: status };
				this.nodes.divider.classList.toggle(this.CSS.divider, status);
				break;
		}
	}
}