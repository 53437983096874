import React from 'react';
import LazyLoad from "react-lazyload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {isMobile} from "../../utils/utils";
import {useNavigate} from "react-router-dom";

const MainstreamPost = ({ title, category = null, author = null, date = null, thumbnailUrl, threadViewUrl, authorViewUrl = null, categoryViewUrl = null, excerpt = null, tags = [] }) => {
	const navigate = useNavigate();

	const Title = () => {
		return <h5 className="font-bold tracking-tight group-hover:text-primary md:text-[20px] md:font-medium">
			<span onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')} title={title} className="cursor-pointer">{title}</span>
		</h5>
	}

	const Thumbnail = () => {
		if (isMobile()) {
			return <div onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')} className="w-full h-full rounded-lg bg-gray-400 cursor-pointer">
				<img className="w-full h-full object-cover md:rounded-none md:rounded-s-lg"
				     src={thumbnailUrl}
				     alt={title}
				/>
			</div>
		} else {
			return <LazyLoad className="w-full h-full rounded-lg bg-gray-400 cursor-pointer">
				<img onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')}
				     className="w-full h-full object-cover md:rounded-none md:rounded-s-lg"
				     src={thumbnailUrl}
				     alt={title}/>
			</LazyLoad>
		}
	}

	const Meta = () => {
		return (author || date || category) &&
			<ul className="flex flex-wrap flex-row whitespace-nowrap items-center mb-2 text-xs text-gray-500 md:text-sm md:font-medium">
				{author &&
					<span className="flex items-center">
						<li className="hidden md:block">
							<span className="text-primary cursor-pointer" onClick={() => navigate(authorViewUrl ? authorViewUrl : '#')} title={author}>{author}</span>
						</li>
					</span>
				}

				{date &&
					<span className="flex items-center">
						<FontAwesomeIcon icon={faCircle}
						                 className="text-[4px] mx-2 hidden md:block md:text-[8px]"/>
						<li>{date}</li>
					</span>
				}

				{category &&
					<span className="flex items-center">
						<FontAwesomeIcon icon={faCircle} className="text-[4px] mx-2 md:text-[8px]"/>
						<li>
							<span className="text-primary cursor-pointer" onClick={() => navigate(categoryViewUrl ? categoryViewUrl : '#')} title={category}>{category}</span>
						</li>
					</span>
				}
			</ul>
	}

	const Excerpt = () => {
		return excerpt && <p className="text-md">{excerpt}</p>
	}

	const Tags = () => {
		return tags.length > 0 && <ul className="mt-2 text-xs font-medium">
			{tags.map((tag, index) => (
				<span key={index}
				   onClick={() => navigate(`/tags/${tag.tag_url}`)}
				   className="inline-block bg-red-100 text-red-800 me-2 mb-1 px-2.5 py-0.5 rounded transition hover:bg-red-200">
					{tag.tag}
				</span>
			))}
		</ul>;
	}

	return (
		<>
			<div className="group flex flex-col bg-white mb-4 md:flex-row md:border md:border-gray-200 rounded-lg">
				<div className="mb-2 md:hidden">
					<Title/>

					<div className="mt-4 flex items-start md:hidden">
						<div className="flex-shrink-0 flex-grow-0 w-1/2 aspect-[3/2] rounded-lg overflow-hidden">
							<Thumbnail/>
						</div>

						<div className="min-w-0 pl-4 font-light text-sm line-clamp-4">
							<Meta/>

							<Excerpt/>
						</div>
					</div>
				</div>

				<div
					className="hidden w-[260px] h-auto max-h-[180px] rounded-lg flex-grow-0 flex-shrink-0 overflow-hidden md:block">
					<Thumbnail/>
				</div>

				<div className="hidden flex-col justify-between p-4 leading-normal md:flex">
					<div className="mb-2">
						<Title/>
					</div>

					{(author || date || category || excerpt) && (
						<div className="mb-3 font-normal text-gray-700">
							<Meta/>

							<Tags/>
						</div>
					)}
				</div>
			</div>

			<hr className="mb-4 bg-primary w-full h-[1px] border-0 rounded opacity-20" />
		</>
	);
}

export default MainstreamPost;