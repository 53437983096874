import React, {useContext} from 'react';

import LazyLoad from 'react-lazyload';

import {faChartLine, faFilm, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HomeContext} from "../../../context/homeContext";
import {useTranslation} from "react-i18next";
import SectionHeader from "../../common/SectionHeader";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const TrendingThreadFirst = ({thread}) => {
	const navigate = useNavigate();

	return <div className="group" title={thread.title}>
		<LazyLoad className="w-full h-auto aspect-[2/1] overflow-hidden rounded-lg bg-gray-400 md:aspect-[2/1.5]">
			<img src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
			     onClick={() => navigate(thread.view_url)}
			     alt={thread.title}
			     className="w-full h-full object-cover rounded-lg"
			/>
		</LazyLoad>

		<h4 className="text-lg font-semibold mt-2 mb-2 line-clamp-2 group-hover:text-primary">{thread.title}</h4>

		<p className="text-sm text-gray-700 mb-4 line-clamp-3">{thread.excerpt}</p>
	</div>;
}

const TrendingThreadSecond = ({thread}) => {
	const navigate = useNavigate();

	return <span className="block text-md font-semibold pb-4 mb-4 border-b border-gray-300 cursor-pointer hover:text-primary"
	             onClick={() => navigate(thread.view_url)}>{thread.title}</span>
}

const TrendingsSection = () => {
	const { i18n, t } = useTranslation();
	const {trendings} = useContext(HomeContext);

	if (trendings) {
		return (
			<div>
				<div className="">
					<SectionHeader title={t('trending')} icon={<FontAwesomeIcon icon={faChartLine} className="mr-2"/>}/>
				</div>

				<div className="flex flex-wrap -mx-2">
					{trendings.map((trending, index) => {
						let forum = trending.forum,
							threads = trending.threads;

						let icon = null;
						if (index === 0) {
							icon = <FontAwesomeIcon icon={faStar} className="mr-2"/>;
						} else {
							icon = <FontAwesomeIcon icon={faFilm} className="mr-2"/>
						}

						if (typeof threads === "object") {
							threads = Object.values(threads);
						}

						return (
							<div key={index} className="w-full px-2 md:w-1/2">
								<h3 className="text-lg text-primary font-bold flex items-center mt-4 mb-2">
									{icon}
									<a href={forum.view_url}>{forum.title}</a>
								</h3>

								<div className="mb-6">
									{threads.map((thread, index) => {
										if (index === 0) {
											return <TrendingThreadFirst key={index} thread={thread}/>
										} else {
											return <TrendingThreadSecond key={index} thread={thread}/>
										}
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		);
	}
};

export default TrendingsSection;