import { useTranslation } from "react-i18next";

const LoadingContent = () => {
	const { i18n, t } = useTranslation();

	return (
		<div className="loading-content text-center font-semibold mt-2">
			{t('loading_content')}
		</div>
	);
}

export default LoadingContent;