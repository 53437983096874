import React, { createContext, useContext, useEffect } from 'react';
import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL, API_KEY } from "../config/config";
import { getQueryParam } from "../utils/queryParams";
import { AuthContext } from "./authContext";
import qs from "qs";

const SearchContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchSearchThreads = async ({ queryKey, pageParam = 1 }) => {
	const [_, { query, visitor }] = queryKey;

	const data = qs.stringify({
		'query': query
	});

	const config = {
		method: 'post',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/search?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		data: data,
		withCredentials: true,
	};

	const response = await axios.request(config);

	let newThreads = response.data.threads;
	if (typeof newThreads === 'object') {
		newThreads = Object.values(newThreads);
	}

	return {
		threads: newThreads,
		pagination: response.data.pagination,
	};
};

const SearchProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);
	const location = useLocation();
	const query = getQueryParam('q', location);

	const {
		data: threadsData,
		isLoading: threadsLoading,
		fetchNextPage: loadMoreThreads,
		hasNextPage: hasMoreThreads,
	} = useInfiniteQuery({
		queryKey: ['searchThreads', { query, visitor }],
		queryFn: fetchSearchThreads,
		enabled: !!visitor && !!query,
		getNextPageParam: (lastPage) => {
			const { pagination } = lastPage;
			return pagination.current_page < pagination.last_page ? pagination.current_page + 1 : undefined;
		},
	});

	useEffect(() => {
		if (visitor && query) {
			queryClient.invalidateQueries(['searchThreads', { query, visitor }]);
		}
	}, [location, visitor, query]);

	const threads = threadsData?.pages.flatMap((page) => page.threads) || [];

	return (
		<SearchContext.Provider value={{ searchQuery: query, threads, threadsLoading, hasMoreThreads, loadMoreThreads }}>
			{children}
		</SearchContext.Provider>
	);
};

const SearchContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<SearchProvider>{children}</SearchProvider>
	</QueryClientProvider>
);

export { SearchContext, SearchContextProvider as SearchProvider };