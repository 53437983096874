import { getFacebookVideoId, getYoutubeVideoId } from "../../utils/embedUtils";
import he from 'he';

const handleString = (string) => {
	// Trim the line-break character \n
	string = string.replace(/^\s+|\s+$/g, '');

	// Explicitly replace &nbsp; with a space character
	string = string.replace(/&nbsp;/g, ' ');

	// Convert <a href="..." [optional attributes]>...</a> to [url='...']...[/url]
	string = string.replace(/<a href="([^"]+)"(?:\s+[^>]*)?>(.*?)<\/a>/gi, "[url='$1']$2[/url]");

	// Convert <code class="inline-code">...</code> to [icode]...[/icode]
	string = string.replace(/<code class="inline-code">(.*?)<\/code>/gi, "[icode]$1[/icode]");

	// Convert <b>...</b> to [b]...[/b]
	string = string.replace(/<b>(.*?)<\/b>/gi, "[b]$1[/b]");

	// Convert <i>...</i> to [i]...[/i]
	string = string.replace(/<i>(.*?)<\/i>/gi, "[i]$1[/i]");

	return string;
}

const blockJsonToBbCode = (editorData) => {
	const bbCode = [];

	editorData.blocks.forEach(block => {
		switch (block.type) {
			case 'paragraph':
				// Process the paragraph text to replace inline HTML tags with BBCode
				let paragraph = he.decode(block.data.text);  // Decode HTML entities
				paragraph = handleString(paragraph);

				if (paragraph && paragraph !== '') {
					bbCode.push(paragraph);
				} else {
					bbCode.push('\n');
				}
				break;

			case 'header':
				let headerText = he.decode(block.data.text);
				headerText = handleString(headerText);

				bbCode.push(`[h${block.data.level}]${headerText}[/h${block.data.level}]`);
				break;

			case 'image':
				const imageUrl = block.data.file.url;

				let altText = he.decode(block.data.caption || '');
				altText = handleString(altText);

				// Check if the image is an attachment from the board
				if (block.data.file.isAttachment) {
					bbCode.push(`[ATTACH type="full" alt="${altText}"]${block.data.file.attachmentId}[/ATTACH]`);
				} else {
					bbCode.push(`[IMG alt="${altText}"]${imageUrl}[/IMG]`);
				}
				break;

			case 'attaches':
				const fileUrl = block.data.file.url;
				let attachmentName = he.decode(block.data.file.name);

				const attachmentSize = block.data.file.size;
				const attachmentId = block.data.file.id;

				// Check if the file is an attachment from the board
				const fileMatch = fileUrl.match(/\/attachments\/(?:[^\/]+)?\.?(\d+)(?:\/|\?|$)/i);
				if (fileMatch) {
					bbCode.push(`[ATTACH type="file" name="${attachmentName}" size="${attachmentSize}"]${attachmentId}[/ATTACH]`);
				}
				break;

			case 'list':
				const listItems = block.data.items.map(item => {
					let decodedItem = he.decode(item);
					decodedItem = handleString(decodedItem);

					return `[*]${decodedItem}`;
				}).join('\n');
				bbCode.push(`[list]\n${listItems}\n[/list]`);
				break;

			case 'linkTool':
				let linkTitle = he.decode(block.data.meta.title);
				linkTitle = handleString(linkTitle);

				bbCode.push(`[url=${block.data.link}]${linkTitle}[/url]`);

				break;

			case 'table':
				const tableData = block.data.content;
				if (tableData && tableData.length > 0) {
					let tableBbCode = [];

					tableData.forEach((item, index) => {
						const rowItems = item.map(cell => {
							let decodedCell = he.decode(cell);
							decodedCell = handleString(decodedCell);

							return decodedCell;
						});

						if (index === 0 && block.data.withHeadings) {
							tableBbCode.push('\n[tr]\n' + rowItems.map(item => `[th]${item}[/th]`).join('\n') + '\n[/tr]');
						} else {
							tableBbCode.push('\n[tr]\n' + rowItems.map(item => `[td]${item}[/td]`).join('\n') + '\n[/tr]');
						}
					});

					bbCode.push('[table]' + tableBbCode.join('\n') + '[/table]');
				}
				break;

			case 'code':
				let codeText = he.decode(block.data.code);
				bbCode.push(`[code]${codeText}[/code]`)
				break;

			case 'embed':
				const service = block.data.service;
				const source = block.data.source;

				let videoId = null;

				switch (service) {
					case 'facebook':
						videoId = getFacebookVideoId(source);
						if (videoId) {
							bbCode.push(`[media=facebook]${videoId}[/media]`);
						}
						break;

					case 'youtube':
						videoId = getYoutubeVideoId(source);
						if (videoId) {
							bbCode.push(`[media=youtube]${videoId}[/media]`);
						}
						break;

					default:
						break;
				}
				break;

			case 'quote':
				let text = he.decode(block.data.text);
				text = handleString(text);

				const alignment = block.data.alignment;
				if (alignment === 'left') {
					bbCode.push(`[quote]${text}[/quote]`);
				} else {
					bbCode.push(`[quote][${alignment}]${text}[/${alignment}][/quote]`);
				}
				break;

			case 'breakline':
				//bbCode.push('\n');
				break;

			default:
				let defaultText = he.decode(block.data.text || '');
				defaultText = defaultText.replace(/&nbsp;/g, ' ');
				bbCode.push(defaultText);
				break;
		}
	});

	return bbCode.join('\n');
}

export default blockJsonToBbCode;
