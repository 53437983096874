import React, { createContext, useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE_URL, API_KEY } from '../config/config';
import { AuthContext } from './authContext';

export const CategoryContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchCategory = async ({ queryKey }) => {
	const [_, { categoryId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/categories/${categoryId}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};

	const response = await axios.request(config);
	const { category, forums, stickyThreads } = response.data;

	return {
		category,
		forums: forums ? Object.values(forums) : [],
		stickyThreads: stickyThreads ? Object.values(stickyThreads) : [],
	};
};

export const CategoryProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);
	const { categoryTitleId } = useParams();

	let categoryId = null;
	const urlPattern = /^([\w-]+)\.(\d+)$/;
	const match = categoryTitleId.match(urlPattern);
	if (match) {
		categoryId = match[2];
	}

	const { data, isLoading, error } = useQuery({
		queryKey: ['category', { categoryId, visitor }],
		queryFn: fetchCategory,
		enabled: !!visitor && !!categoryId, // Only fetch if visitor and categoryId are present
	});

	return (
		<CategoryContext.Provider value={{ category: data?.category, forums: data?.forums, stickyThreads: data?.stickyThreads, isLoading, error }}>
			{children}
		</CategoryContext.Provider>
	);
};

export const CategoryContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<CategoryProvider>{children}</CategoryProvider>
	</QueryClientProvider>
);
