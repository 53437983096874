import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FloatingLabelInput from "../../common/FloatingLabelInput";

const CustomFields = ({source, source_url, onCustomFieldValueChange}) => {
	const { t } = useTranslation();

	const [customFieldsData, setCustomFieldsData] = useState({
		source: source || '',
		source_url: source_url || ''
	});

	const handleCustomFieldsChange = (fieldId, value) => {
		setCustomFieldsData((prevData) => ({
			...prevData,
			[fieldId]: value
		}));
	}

	useEffect(() => {
		if (onCustomFieldValueChange) {
			onCustomFieldValueChange(customFieldsData);
		}
	}, [customFieldsData, onCustomFieldValueChange]);

	return (
		<>
			<h1 className="text-2xl font-bold mb-4">{t('extra')} ({t('optional')})</h1>

			<div className="space-y-4 px-4 py-2 shadow-xl rounded-xl md:px-8 md:py-4 md:shadow-2xl md:rounded-2xl">
				<FloatingLabelInput
					inputId="custom-fields-source"
					title={t('source')}
					defaultValue={customFieldsData.source}
					onChange={(e) => handleCustomFieldsChange('source', e.target.value)}
				/>

				<FloatingLabelInput
					inputId="custom-fields-source-url"
					title={t('source_url')}
					defaultValue={customFieldsData.source_url}
					onChange={(e) => handleCustomFieldsChange('source_url', e.target.value)}
				/>
			</div>
		</>
	);
}

export default CustomFields;