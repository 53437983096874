import React, { createContext, useContext } from 'react';
import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE_URL, API_KEY } from "../config/config";
import { AuthContext } from "./authContext";

export const MemberContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchUser = async ({ queryKey }) => {
	const [_, { userId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/users/${userId}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true
	};

	const response = await axios.request(config);
	return response.data.user;
};

const fetchUserThreads = async ({ pageParam = 1, queryKey }) => {
	const [_, { userId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/users/${userId}/threads?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true
	};

	const response = await axios.request(config);
	const newThreads = Object.values(response.data.threads || {});
	return { threads: newThreads, pagination: response.data.pagination };
};

const fetchUserFollowers = async ({ pageParam = 1, queryKey }) => {
	const [_, { userId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/users/${userId}/followers?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true
	};

	const response = await axios.request(config);
	const newFollowers = Object.values(response.data.followers || {});
	return { followers: newFollowers, pagination: response.data.pagination };
};

const fetchUserTrophies = async ({ queryKey }) => {
	const [_, { userId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/users/${userId}/trophies`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true
	};

	const response = await axios.request(config);
	return Object.values(response.data.trophies || {});
};

export const MemberProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);
	const { usernameId } = useParams();

	const urlPattern = /^([\w-]+)\.(\d+)$/;
	const match = usernameId.match(urlPattern);
	const userId = match ? match[2] : null;

	const { data: user } = useQuery({
		queryKey: ['user', { userId, visitor }],
		queryFn: fetchUser,
		enabled: !!visitor && !!userId,
	});

	const {
		data: threadsData,
		fetchNextPage: loadMoreThreads,
		hasNextPage: hasMoreThreads,
		isLoading: threadsLoading
	} = useInfiniteQuery({
		queryKey: ['userThreads', { userId, visitor }],
		queryFn: fetchUserThreads,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor && !!userId,
	});

	const threads = threadsData?.pages.flatMap(page => page.threads) || [];

	const {
		data: followersData,
		fetchNextPage: loadMoreFollowers,
		hasNextPage: hasMoreFollowers,
		isLoading: followersLoading
	} = useInfiniteQuery({
		queryKey: ['userFollowers', { userId, visitor }],
		queryFn: fetchUserFollowers,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor && !!userId,
	});

	const followers = followersData?.pages.flatMap(page => page.followers) || [];

	const { data: trophies = [] } = useQuery({
		queryKey: ['userTrophies', { userId, visitor }],
		queryFn: fetchUserTrophies,
		enabled: !!visitor && !!userId,
	});

	return (
		<MemberContext.Provider value={{
			user,
			threads, threadsLoading, hasMoreThreads, loadMoreThreads,
			followers, followersLoading, hasMoreFollowers, loadMoreFollowers,
			trophies
		}}>
			{children}
		</MemberContext.Provider>
	);
};

export const MemberContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<MemberProvider>{children}</MemberProvider>
	</QueryClientProvider>
);