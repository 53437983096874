import React, { createContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL, API_KEY, boardUrl } from "../config/config";

const AuthContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchDecryptedUserId = async () => {
	const encryptedUserId = localStorage.getItem('encryptedUserId');
	if (encryptedUserId) {
		try {
			const config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${boardUrl}/user-storage/decrypt-user-id?encrypted_user_id=${encryptedUserId}`,
				withCredentials: true
			};
			const response = await axios.request(config);
			return response.data.user_id ? Number.parseInt(response.data.user_id) : null;
		} catch (error) {
			console.error('Failed to decrypt user ID:', error);
			return null;
		}
	} else {
		return null;
	}
};

const fetchUser = async ({ queryKey }) => {
	const [, userId] = queryKey;
	if (!userId) return { user_id: 0, username: 'Guest' }; // Return guest if no userId

	// Check sessionStorage for cached user data
	const cachedUser = sessionStorage.getItem(`user_${userId}`);
	if (cachedUser) {
		return JSON.parse(cachedUser);
	}

	try {
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${API_BASE_URL}/me`,
			headers: {
				'XF-Api-Key': API_KEY,
				'XF-Api-User': userId
			},
			withCredentials: true
		};
		const response = await axios.request(config);
		sessionStorage.setItem(`user_${userId}`, JSON.stringify(response.data.me));
		return response.data.me;
	} catch (error) {
		console.error('Failed to fetch user:', error);
		return { user_id: 0, username: 'Guest' }; // Return guest in case of error
	}
};

const AuthProvider = ({ children }) => {
	const { data: userId } = useQuery({
		queryKey: ['decryptedUserId'],
		queryFn: fetchDecryptedUserId,
		cacheTime: 0, // Do not cache decrypted user ID
	});

	const { data: visitor, isLoading: visitorLoading } = useQuery({
		queryKey: ['user', userId],
		queryFn: fetchUser,
		enabled: userId !== undefined, // Only fetch user data if userId is defined (even if null)
	});

	return (
		<AuthContext.Provider value={{ visitor, visitorLoading }}>
			{children}
		</AuthContext.Provider>
	);
};

const AuthContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<AuthProvider>{children}</AuthProvider>
	</QueryClientProvider>
);

export { AuthContext, AuthContextProvider as AuthProvider };
