import React, {useContext} from 'react';
import {footerLogoUrl} from "../../config/config";
import {faAddressCard, faTag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTiktok, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {FooterContext} from "../../context/footerContext";

const footerColumnLink = function(href, title) {
	return (
		<li className='pb-3'><a href={href} className='hover:underline'>{title}</a></li>
	);
}

const Footer = () => {
	const {footerColumns} = useContext(FooterContext);

	return (
		<footer>
			<div className='bg-primary text-white py-10 px-4'>
				<div className="container mx-auto lg:max-w-[1150px]">
					<img src={footerLogoUrl} className="h-10 mx-auto md:h-5 md:mx-0" alt="Golive.vn"/>
					<p className="mt-2 hidden md:block">GOLIVE.VN - MẠNG THÔNG TIN GIẢI TRÍ DÀNH CHO GIỚI TRẺ</p>
				</div>

				<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 pt-5 lg:max-w-[1150px]">
					{footerColumns && (
						<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
							<div className="hidden md:block">
								<h3 className="font-bold">
									<span className='inline-block border-b-2'>CHUYÊN MỤC</span>
								</h3>

								<ul className="mt-4 space-y-1">
									{footerColumns[0].map((link, index) => (
										<div key={index}>{footerColumnLink(link.url, link.title)}</div>
									))}
								</ul>
							</div>

							<div className="hidden md:block">
								<h3 className="font-bold">&nbsp;</h3>
								<ul className="mt-4 space-y-1">
									{footerColumns[1].map((link, index) => (
										<div key={index}>{footerColumnLink(link.url, link.title)}</div>
									))}
								</ul>
							</div>

							<div className="hidden md:block">
								<h3 className="font-bold">&nbsp;</h3>
								<ul className="mt-4 space-y-1">
									{footerColumns[2].map((link, index) => (
										<div key={index}>{footerColumnLink(link.url, link.title)}</div>
									))}
								</ul>
							</div>
						</div>
					)}

					<div>
						<h3 className="hidden font-bold md:block">
							<span className='inline-block border-b-2'>LIÊN HỆ</span>
						</h3>

						<div className='mt-4'>
							<p>Trụ sở Hà Nội</p>
							<p>155 Trần Đăng Ninh, P. Dịch Vọng, Q. Cầu Giấy, TP Hà Nội</p>
						</div>

						<div className='mt-4'>
							<p className='font-bold'>Kết nối với chúng tôi</p>

							<div className="flex space-x-4 mt-2">
								<a href="#" className="text-white">
									<FontAwesomeIcon icon={faFacebook}/>
								</a>
								<a href="#" className="text-white">
									<FontAwesomeIcon icon={faYoutube}/>
								</a>
								<a href="#" className="text-white">
									<FontAwesomeIcon icon={faTiktok}/>
								</a>
								<a href="#" className="text-white">
									<FontAwesomeIcon icon={faInstagram}/>
								</a>
							</div>
						</div>
					</div>

					<div>
						<div className="border-t border-white border-opacity-60 pt-5 mb-8 md:border-t-0 md:pt-0">
							<h3 className="hidden font-bold md:block">
								<span className='inline-block border-b-2'>HỢP TÁC QUẢNG CÁO</span>
							</h3>

							<div className="mt-4">
								<div className="flex items-center mb-2">
									<FontAwesomeIcon icon={faAddressCard} className="mr-1" />
									<span className="font-bold">Hợp tác nội dung</span>
								</div>

								<p>contact@golive.vn</p>
							</div>
						</div>

						<div className="border-t border-white border-opacity-60 pt-5 md:mb-8 md:border-t-0 md:pt-0">
							<div className="flex items-center mb-2">
								<FontAwesomeIcon icon={faTag} className="mr-1" />
								<span className="font-bold">Hợp tác quảng cáo</span>
							</div>

							<div className="flex justify-between md:block">
								<p>Email: admin@wingroup.vn</p>
								<p>Hotline: 0388 653 999</p>
							</div>
						</div>

						<button
							className="hidden px-4 py-2 border-2 border-white text-white font-bold rounded transition duration-300 hover:bg-white hover:text-primary md:block">
							NHẬN BÁO GIÁ QUẢNG CÁO
						</button>
					</div>
				</div>

				<div className="mt-5 md:mt-10 md:pt-5">
					<div className="container mx-auto border-t border-white border-opacity-60 grid grid-cols-1 md:grid-cols-2 gap-8 pt-5 lg:max-w-[1150px]">
						<div>
							<p className="font-bold">Chịu trách nhiệm nội dung</p>
							<p>Nguyễn Công Việt</p>
						</div>

						<div className="border-t border-white border-opacity-60 pt-5 md:border-t-0 md:pt-0">
							<p className="font-bold">Thư ký biên tập</p>
							<p>Nguyễn Nga Quỳnh</p>
						</div>
					</div>
				</div>
			</div>

			<div className='bg-[#262436] text-white'>
				<div className="container text-sm text-center mx-auto px-4 py-5 md:text-md lg:max-w-[1150px]">
					<p>Giấy phép thiết lập MXH số 273/GP-BTTTT do Bộ Thông tin và Truyền thông cấp ngày 03/06/2022</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
