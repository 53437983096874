import React, {useContext} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingContent from "../../common/LoadingContent";
import LoadingNoMoreContent from "../../common/LoadingNoMoreContent";
import MainstreamPost from "../../common/MainstreamPost";
import Highlighter from "react-highlight-words";
import {TagContext} from "../../../context/tagContext";
import RelativeTime from "../../common/RelativeTime";
import {isMobile} from "../../../utils/utils";

const ResultSection = () => {
	const {
		tag, tagLoading, threads, hasMoreThreads, loadMoreThreads,
	} = useContext(TagContext);

	if (tag && threads) {
		if (!tagLoading) {
			return (
				<InfiniteScroll
					next={loadMoreThreads}
					hasMore={hasMoreThreads}
					loader={<LoadingContent/>}
					dataLength={threads.length}
					endMessage={<LoadingNoMoreContent/>}
				>
					{threads.map((thread, index) => {
						let title = <Highlighter
							highlightClassName="highligh-text bg-transparent text-primary font-bold"
							searchWords={[tag.tag]}
							autoEscape={true}
							textToHighlight={thread.title}
						/>;

						let excerpt = <Highlighter
							highlightClassName="highligh-text bg-transparent font-bold"
							searchWords={[tag.tag]}
							autoEscape={true}
							textToHighlight={thread.excerpt}
						/>;

						return (
							<div key={index}>
								<MainstreamPost title={title}
								                category={thread.Forum.title}
								                author={thread.username}
								                date={<RelativeTime timestamp={thread.post_date} />}
								                thumbnailUrl={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
								                threadViewUrl={thread.view_url}
								                authorViewUrl={thread.User ? thread.User.view_url : null}
								                categoryViewUrl={thread.Forum.view_url}
								                excerpt={excerpt}
								                tags={Object.values(thread.tags)}
								/>
							</div>
						);
					})}
				</InfiniteScroll>
			);
		} else {
			return <LoadingContent />
		}
	} else {
		return <LoadingNoMoreContent />
	}
}

export default ResultSection;