import {ForumListContext, ForumListProvider} from "../../context/forumListContext";
import Body from "../common/Body";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import Category from "../common/NodeTree/Category";
import LoadingContent from "../common/LoadingContent";

const MainContent = () => {
	const { i18n, t } = useTranslation();
	const { nodeList, nodeListLoading } = useContext(ForumListContext);
	const navigate = useNavigate();

	return (
		<>
			<section className="mb-4">
				<h1 className="text-2xl font-bold mb-4">{t('post_new_thread')}</h1>
			</section>

			{nodeListLoading && <LoadingContent />}

			{nodeList && (
				<section className="mb-4">
					{Object.entries(nodeList.nodeTree).map(([categoryId, childNodeIds]) => {
						return <Category key={categoryId}
						                 nodes={nodeList.nodes}
						                 node={nodeList.nodes[categoryId]}
						                 childNodeIds={childNodeIds}
						/>;
					})}
				</section>
			)}
		</>
	);
}

const ForumPostThreadChooser = () => {
	return (
		<ForumListProvider>
			<Body mainContent={<MainContent/>} sidebarContent={null}/>
		</ForumListProvider>
	);
}

export default ForumPostThreadChooser;