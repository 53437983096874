import React, {useContext } from 'react';

import HighlightSection from "./HighlightSection";

import MainstreamPost from "../../common/MainstreamPost";
import {HomeContext} from "../../../context/homeContext";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import SectionHeader from "../../common/SectionHeader";
import ReviewSection from "./ReviewSection";
import SpecialSection from "./SpecialSection";
import RelativeTime from "../../common/RelativeTime";
import {isMobile} from "../../../utils/utils";

const MainstreamPostsSection = () => {
	const { i18n, t } = useTranslation();

	const {
		mainstreamPosts, hasMoreMainstreamPosts, loadMoreMainstreamPosts
	} = useContext(HomeContext);

	if (mainstreamPosts && mainstreamPosts.length > 0) {
		return (
			<div>
				<div className="hidden md:block">
					<SectionHeader title={t('general_info')} icon={<FontAwesomeIcon icon={faChartBar} className="mr-2"/>} />
				</div>

				{mainstreamPosts.map((entry, index) => {
					return (
						<div key={index}>
							{(index === 5 && !isMobile()) &&
								<>
									<div className="mb-4">
										<ReviewSection/>
									</div>

									<section className="bg-primary text-white p-4 mb-4 -mx-4 rounded-none md:mb-8 md:-mx-0 md:rounded-lg">
										<SpecialSection/>
									</section>
								</>
							}

							{(index === 5 && isMobile()) &&
								<div className="mb-4">
									<HighlightSection />
								</div>
							}

							{(index === 10 && !isMobile()) &&
								<div className="mb-4">
									<HighlightSection />
								</div>
							}

							<MainstreamPost title={entry.title ? entry.title : entry.Thread.title}
							                category={entry.Thread.Forum.title}
							                author={entry.Thread.username}
							                date={<RelativeTime timestamp={entry.Thread.post_date} />}
							                thumbnailUrl={isMobile() ? entry.Thread.mobile_thumbnail_url : entry.Thread.thumbnail_url}
							                threadViewUrl={entry.Thread.view_url}
							                authorViewUrl={entry.Thread.User ? entry.Thread.User.view_url : null}
							                categoryViewUrl={entry.Thread.Forum.view_url}
							                excerpt={entry.excerpt}
							/>
						</div>
					);
				})}

				{hasMoreMainstreamPosts && (
					<div className="text-center mt-4">
						<button onClick={loadMoreMainstreamPosts}
						        className="w-auto px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
							{t('load_more')}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default MainstreamPostsSection;