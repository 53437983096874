import React, {Suspense, useEffect} from 'react';

import "./i18n";

import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';

import { getQueryParam } from './utils/queryParams';

import { AuthProvider } from './context/authContext';
import { TagProvider } from './context/tagContext';

import Header from './components/common/Header';
import Footer from './components/common/Footer';

import Homepage from './components/Homepage/Homepage';

import './index.css';
import Logout from "./components/Logout";
import DynamicRouteHandler from "./components/DynamicRouterHandler";
import Member from "./components/Member/Member";
import Search from "./components/Search/Search";
import Tag from "./components/Tag/Tag";
import Category from "./components/Category/Category";
import {HeaderProvider} from "./context/headerContext";
import ScrollToTopRouter from "./components/ScrollToTopRouter";
import {SidebarProvider} from "./context/sidebarContext";
import {FooterProvider} from "./context/footerContext";
import PostEdit from "./components/Post/PostEdit";
import ForumPostThreadChooser from "./components/ForumPostThread/ForumPostThreadChooser";

const QueryParamHandler = () => {
    const location = useLocation();

    useEffect(() => {
        const encryptedUserId = getQueryParam('encryptedUserId', location);
        if (encryptedUserId) {
            localStorage.setItem('encryptedUserId', encryptedUserId);
        }
    }, [location]);

    return null;
};

function App() {
    return (
        <Suspense fallback="loading">
            <AuthProvider><TagProvider><HeaderProvider><FooterProvider><SidebarProvider>

            <Router>
                <ScrollToTopRouter />

                <QueryParamHandler />

                <Header />

                <Routes>
                    <Route path="/" element={<Homepage />} />

                    <Route path="members/:usernameId" element={<Member />} />

                    <Route path="categories/:categoryTitleId" element={<Category />} />

                    <Route path="search" element={<Search />} />

                    <Route path="tags/:tagUrl" element={<Tag />} />

                    <Route path="posts/:postId" element={<PostEdit />} />

                    <Route path="post-thread" element={<ForumPostThreadChooser />} />

                    <Route path="logout" element={<Logout />} />

                    <Route path="*" element={<DynamicRouteHandler />} />
                </Routes>

                <Footer />
            </Router>

            </SidebarProvider></FooterProvider></HeaderProvider></TagProvider></AuthProvider>
        </Suspense>
    );
}

export default App;
