import React, { createContext, useContext, useState } from 'react';
import { useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { AuthContext } from './authContext';
import { API_BASE_URL, API_KEY } from '../config/config';

const HeaderContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchLatestNewsPosts = async ({ pageParam = 1, queryKey }) => {
	const [_, { visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/latest-news-posts?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};

	const response = await axios.request(config);
	const newLatestNewsPosts = Object.values(response.data.threads || {});

	return {
		latestNewsPosts: newLatestNewsPosts,
		pagination: response.data.pagination,
	};
};

const HeaderProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);

	const [isCategoryListPopupActive, setIsCategoryListPopupActive] = useState(false);
	const [isLatestNewsSectionActive, setIsLatestNewsSectionActive] = useState(false);

	const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
		queryKey: ['latestNewsPosts', { visitor }],
		queryFn: fetchLatestNewsPosts,
		getNextPageParam: (lastPage) => {
			return lastPage.pagination.current_page < lastPage.pagination.last_page
				? lastPage.pagination.current_page + 1
				: undefined;
		},
		enabled: !!visitor,
	});

	const latestNewsPosts = data?.pages.flatMap(page => page.latestNewsPosts) || [];
	const hasMoreLatestNewsPosts = hasNextPage;

	const toggleCategoryListPopup = () => {
		setIsCategoryListPopupActive(prev => !prev);
	};

	const toggleLatestNewsSectionPopup = () => {
		setIsLatestNewsSectionActive(prev => !prev);
	};

	const closeLatestNewsSectionPopup = () => {
		setIsLatestNewsSectionActive(false);
	};

	return (
		<HeaderContext.Provider value={{
			latestNewsPosts, latestNewsPostsLoading: isLoading, hasMoreLatestNewsPosts, loadMoreLatestNewsPosts: fetchNextPage,
			isCategoryListPopupActive, toggleCategoryListPopup,
			isLatestNewsSectionActive, toggleLatestNewsSectionPopup, closeLatestNewsSectionPopup
		}}>
			{children}
		</HeaderContext.Provider>
	);
};

const HeaderContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<HeaderProvider>{children}</HeaderProvider>
	</QueryClientProvider>
);

export { HeaderContext, HeaderContextProvider as HeaderProvider };
