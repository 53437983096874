import React from 'react';

const MemberAvatar = ({ imgUrl = null }) => {
	return (
		<a className={`block w-full h-full relative text-center rounded-full ${!imgUrl && ('overflow-hidden bg-gray-300')}`}
			href={imgUrl ? imgUrl : '#'} target={imgUrl ? '_blank' : '_self'}>

			{imgUrl ? (
				<img src={imgUrl}
				     alt={imgUrl ? imgUrl : ''}
				     className="w-full h-full rounded-full object-cover shadow shadow-gray-800"
				/>
			) : (
				<svg className="absolute w-full h-full -bottom-2.5 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
				     xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
					      clipRule="evenodd"></path>
				</svg>
			)}

		</a>
	);
}

export default MemberAvatar;