import React from 'react';
import {isValidatedUser} from "../../../utils/user";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MemberAvatar from "../../common/MemberAvatar";
import {useTranslation} from "react-i18next";
import RelativeTime from "../../common/RelativeTime";

const HeroSection = ({ user }) => {
	const { i18n, t } = useTranslation();

	if (user) {
		return (
			<div className="relative bg-gray-100 px-4 py-20">
				{user.profile_banner_urls.l && (
					<div className="absolute top-0 bottom-0 left-0 right-0 z-5">
						<img src={user.profile_banner_urls.l} alt={user.username}
						     className="w-full h-full object-cover"/>
					</div>
				)}

				<div className="relative flex flex-col items-center z-10">
					<div className="mb-4 w-24 h-24 p-1 ring-4 ring-blue-700 rounded-full">
						<MemberAvatar imgUrl={user.avatar_urls.o}/>
					</div>

					<h1 className="text-2xl font-bold flex items-center mb-4">
						{user.username}
						{(user.secondary_group_ids && isValidatedUser(Object.values(user.secondary_group_ids))) && (
							<FontAwesomeIcon icon={faCheckCircle} className="text-blue-700 ml-2"/>
						)}
					</h1>

					<div className="text-gray-500 mb-2">
					<span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
						{user.user_title}
					</span>
						<span>
							{t('join_date')}: <RelativeTime timestamp={user.register_date} />
						</span>
					</div>

					<div className="text-gray-500">
						<span>{t('posts')}: <b>{user.message_count}</b></span>
						<span className="ml-2">{t('reaction_score')}: <b>{user.reaction_score}</b></span>
						<span className="ml-2">{t('followers')}: <b>{user.follower_count}</b></span>
					</div>
				</div>
			</div>
		);
	}
};

export default HeroSection;
