import React from 'react';
import {boardUrl} from "../config/config";
import {useTranslation} from "react-i18next";

const Logout = () => {
	const {t} = useTranslation();

	localStorage.removeItem('encryptedUserId');

	return (
		<section className="flex flex-col items-center justify-center min-h-[60vh]">
			<div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
				<div className="mx-auto max-w-screen-sm text-center">
					<h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">
						{t('logged_out_successfully')}
					</h1>

					<p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
						{t('logged_out_message')}
					</p>

					<a href={boardUrl}
					   className="inline-flex text-white bg-primary hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
						{t('back_to_homepage')}
					</a>
				</div>
			</div>
		</section>
	);
};

export default Logout;
