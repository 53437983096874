import React, { createContext, useContext } from 'react';
import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL, API_KEY } from "../config/config";
import { useParams } from "react-router-dom";
import { AuthContext } from "./authContext";

const TagContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchTag = async ({ queryKey }) => {
	const [_, { tagUrl }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/tags/${tagUrl}?limit=20`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': 1, // Public API, can use superuser ID
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data.tag;
};

const fetchTagResults = async ({ pageParam = 1, queryKey }) => {
	const [_, { tagUrl }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/tags/${tagUrl}/results?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': 1, // Public API, can use superuser ID
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchFeaturedTags = async () => {
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/tags/featured?limit=6`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': 1, // Public API, can use superuser ID
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data.tags;
};

const TagProvider = ({ children }) => {
	const { tagUrl } = useParams();
	const { visitor } = useContext(AuthContext);

	const { data: tag, isLoading: tagLoading } = useQuery({
		queryKey: ['tag', { tagUrl }],
		queryFn: fetchTag,
		enabled: !!tagUrl,
	});

	const {
		data: tagResultsData,
		fetchNextPage: loadMoreThreads,
		hasNextPage: hasMoreThreads,
	} = useInfiniteQuery({
		queryKey: ['tagResults', { tagUrl }],
		queryFn: fetchTagResults,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!tagUrl,
	});

	const { data: featuredTags } = useQuery({
		queryKey: ['featuredTags'],
		queryFn: fetchFeaturedTags,
	});

	const threads = tagResultsData?.pages.flatMap(page => page.threads) || [];

	return (
		<TagContext.Provider
			value={{
				tag,
				threads, tagLoading, hasMoreThreads, loadMoreThreads,
				featuredTags
			}}
		>
			{children}
		</TagContext.Provider>
	);
};

const TagContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<TagProvider>{children}</TagProvider>
	</QueryClientProvider>
);

export { TagContext, TagContextProvider as TagProvider };