import React, {useContext} from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import LazyLoad from 'react-lazyload';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {faRss} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HomeContext} from "../../../context/homeContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const SpecialItem = ({thread}) => {
	const navigate = useNavigate();

	return (
		<div onClick={() => navigate(thread.view_url)} className="w-[279px] h-full bg-[#7E643E] rounded-lg shadow">
			<LazyLoad className="w-full aspect-[1.8/2] rounded-t-lg bg-gray-400 cursor-pointer">
				<img className="rounded-t-lg w-full h-full object-cover"
				     src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
				     alt={thread.title}/>
			</LazyLoad>

			<div className="py-3 px-2 text-justify cursor-pointer">
				<h5 className="mb-2 font-semibold text-white line-clamp-3">{thread.title}</h5>
			</div>
		</div>
	);
}

const SpecialSection = () => {
	const {i18n, t} = useTranslation();
	const {specialPosts} = useContext(HomeContext);

	if (specialPosts && specialPosts.length > 0) {
		return (
			<div>
				<div className="flex items-center mb-4">
					<FontAwesomeIcon icon={faRss} className="text-lg" />
					<h2 className="text-xl font-bold ml-2">{t('special')}</h2>
				</div>

				<Swiper
					className="highlight-section-slide"
					modules={[Pagination]}
					slidesPerView={'auto'}
					spaceBetween={20}
					pagination={{clickable: true}}
				>
					{specialPosts.map((thread, index) => (
						<SwiperSlide key={index} className="!w-[279px] !h-auto">
							<SpecialItem thread={thread}/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		);
	}
};

export default SpecialSection;