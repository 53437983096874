import React, {useContext} from 'react';

import FloatingActionButtons from "./FloatingActionButtons";
import {HeaderContext} from "../../context/headerContext";
import {SidebarContext} from "../../context/sidebarContext";
import PlaceholderLoading from "react-placeholder-loading";
import PreloadImage from "./PreloadImage";

const DefaultSideContent = () => {
	const {sidebarAds} = useContext(SidebarContext);

	if (sidebarAds) {
		return (
			<div className="side-container">
				{sidebarAds.map((ads, index) => (
					<div className="w-full h-auto mb-4" key={index}>
						<a href={ads.url} title={ads.title} className="block">
							<PreloadImage src={ads.img_url} alt={ads.title} className="w-full h-auto" />
						</a>
					</div>
				))}
			</div>
		);
	} else {
		return (
			<div className="side-container">
				<div className="w-full h-auto aspect-square mb-4">
					<PlaceholderLoading width={'100%'} height={'100%'} shape={'rect'}/>
				</div>

				<div className="w-full h-auto aspect-square">
					<PlaceholderLoading width={'100%'} height={'100%'} shape={'rect'}/>
				</div>
			</div>
		)
	}
}

const Body = ({mainContent = null, sidebarContent = null, sidenavContent = null, heroContent = null, heroContentBottom = null}) => {
	const {isCategoryListPopupActive, isLatestNewsSectionActive} = useContext(HeaderContext);

	return (
		<div className={`page-body min-h-screen w-full bg-white ${(isCategoryListPopupActive || isLatestNewsSectionActive) && 'is-hidden'}`}>
			{heroContent && (
				<div className="hero-section w-full lg:mb-8">
					{heroContent}
				</div>
			)}

			<div className="container h-full w-full mx-auto flex flex-col lg:flex-row lg:max-w-[1150px]">
				{sidenavContent && (
					<div className="sidenav w-full h-auto p-4 pb-0 lg:mb-0 lg:w-[280px] lg:flex-grow-0 lg:flex-shrink-0">
						<div className="sticky top-4">
							{sidenavContent === 'default'
								? <DefaultSideContent />
								: (
									<div className="side-container">
										{sidenavContent}
									</div>
								)
							}
						</div>
					</div>
				)}

				<div className="main-content w-full min-h-full flex-grow p-4 min-w-0">
					{mainContent}
				</div>

				{sidebarContent && (
					<div className="sidebar hidden h-auto p-4 lg:block lg:w-[280px] lg:flex-grow-0 lg:flex-shrink-0">
						<div className="sticky top-4">
							{sidebarContent === 'default'
								? <DefaultSideContent />
								: (
									<div className="side-container">
										{sidenavContent}
									</div>
								)
							}
						</div>
					</div>
				)}
			</div>

			{heroContentBottom && (
				<div className="hero-section hero-section-bottom w-full mt-4 lg:mt-8">
					{heroContentBottom}
				</div>
			)}

			<FloatingActionButtons />
		</div>
	);
};

export default Body;