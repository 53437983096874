import React from 'react';
import {faTags} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

const TagListSection = ({tags}) => {
	const navigate = useNavigate();

	if (tags && tags.length > 0) {
		return (
			<div>
				<div className="inline-block text-sm text-primary font-bold mb-2 md:text-xl md:block">
					<FontAwesomeIcon icon={faTags} className="mr-2" />
					<span>Tags</span>
				</div>

				<ul className="inline-block md:block">
					{tags.map((tag, index) => (
						<span key={index} onClick={() => navigate(`tags/${tag.tag_url}`)}
						   className="inline-block bg-red-100 text-red-800 me-2 mb-1 px-2.5 py-0.5 rounded transition cursor-pointer hover:bg-red-200 max-md:text-sm">
							{tag.tag}
						</span>
					))}
				</ul>
			</div>
		);
	}
}

export default TagListSection;