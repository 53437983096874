import {createContext, useContext, useEffect, useState} from "react";
import {QueryClient, QueryClientProvider, useMutation, useQuery} from "@tanstack/react-query";
import {API_BASE_URL, API_KEY} from "../config/config";
import axios from "axios";
import {generateAttachmentKey} from "../utils/utils";
import {AuthContext} from "./authContext";
import {useParams} from "react-router-dom";

export const PostContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	}
});

const fetchPost = async ({ queryKey }) => {
	const [_, { postId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/posts/${postId}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};

	const response = await axios.request(config);
	return response.data.post;
}

const fetchPostEditAttachmentKey = async ({ queryKey }) => {
	const [_, { postId, visitor }] = queryKey;

	return generateAttachmentKey(visitor, 'post', 'post_id', postId);
}

const submitPost = async ({ postId, message, attachmentKey, visitor }) => {
	const data = new URLSearchParams();
	data.append('message', message);
	if (attachmentKey) {
		data.append('attachmentKey', attachmentKey);
	}

	const response = await axios.post(`${API_BASE_URL}/posts/${postId}`, data, {
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		withCredentials: true
	});

	return response.data;
}

export const PostProvider = ({ children }) => {
	const {visitor} = useContext(AuthContext);
	const { postId } = useParams();

	const [thread, setThread] = useState(null);

	const { data: post, isLoading: postLoading, error: postError } = useQuery({
		queryKey: ['post', { postId, visitor }],
		queryFn: fetchPost,
		enabled: !!visitor && !!postId,
	});

	const { data: attachmentKey, isLoading: attachmentKeyLoading, error: attachmentKeyError } = useQuery({
		queryKey: ['attachmentKey', {postId, visitor}],
		queryFn: fetchPostEditAttachmentKey,
		enabled: !!visitor && !!postId,
	});

	const submitPostMutation = useMutation({
		mutationFn: async ({ postId, message, attachmentKey }) => {
			return submitPost({postId, message, attachmentKey, visitor});
		},
		onError: (error) => {
			console.error('Error submitting post:', error);
		},
		onSuccess: (data) => {
			console.log('Successfully submitted post:', data);
		}
	});

	useEffect(() => {
		if (post) {
			setThread(post.Thread);
		}
	}, [post]);

	return (
		<PostContext.Provider value={{
			post, thread,
			submitPostMutation,
			attachmentKey, attachmentKeyLoading,
		}}>
			{children}
		</PostContext.Provider>
	);
};

export const PostContextProvider = ({ children }) => {
	<QueryClientProvider client={queryClient}>
		<PostProvider>{children}</PostProvider>
	</QueryClientProvider>
};