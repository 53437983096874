import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faFilm, faGamepad,
	faGlobe,
	faGlobeAsia, faHatWizard,
	faMoneyBill,
	faMusic,
	faPhotoFilm,
	faSchool, faShoppingBag,
	faStar, faTimes, faUsers, faVideo
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {HeaderContext} from "../../context/headerContext";
import {boardUrl} from "../../config/config";
import {faTiktok} from "@fortawesome/free-brands-svg-icons";

const categories = [
	{
		name: 'SAO & ĐỜI SỐNG',
		icon: <FontAwesomeIcon icon={faStar} />,
		url: `${boardUrl}/index.php?forums/15`
	},
	{
		name: 'ÂM NHẠC',
		icon: <FontAwesomeIcon icon={faMusic} />,
		url: `${boardUrl}/index.php?forums/3`
	},
	{
		name: 'GIỚI TRẺ',
		icon: <FontAwesomeIcon icon={faGlobeAsia} />,
		url: `${boardUrl}/index.php?forums/10`
	},
	{
		name: 'PHIM',
		icon: <FontAwesomeIcon icon={faFilm} />,
		url: `${boardUrl}/index.php?forums/24`
	},
	{
		name: 'ĐIỆN ẢNH & GIẢI TRÍ',
		icon: <FontAwesomeIcon icon={faPhotoFilm} />,
		url: `${boardUrl}/index.php?forums/2`
	},
	{
		name: 'THẾ GIỚI ĐÓ ĐÂY',
		icon: <FontAwesomeIcon icon={faGlobe} />,
		url: `${boardUrl}/index.php?forums/25`
	},
	{
		name: 'HỌC ĐƯỜNG',
		icon: <FontAwesomeIcon icon={faSchool} />,
		url: ''
	},
	{
		name: 'TÀI NĂNG TRẺ',
		icon: <FontAwesomeIcon icon={faUsers} />,
		url: `${boardUrl}/index.php?forums/13`
	},
	{
		name: 'GAME THỦ',
		icon: <FontAwesomeIcon icon={faGamepad} />,
		url: `${boardUrl}/index.php?forums/23`
	},
	{
		name: 'IDOL TIKTOK',
		icon: <FontAwesomeIcon icon={faTiktok} />,
		url: `${boardUrl}/index.php?forums/16`
	},
	{
		name: 'MONEY-Z',
		icon: <FontAwesomeIcon icon={faMoneyBill} />,
		url: `${boardUrl}/index.php?forums/22`
	},
	{
		name: 'VIDEO',
		icon: <FontAwesomeIcon icon={faVideo} />,
		url: `${boardUrl}/index.php?forums/14`
	},
	{
		name: 'THỜI TRANG',
		icon: <FontAwesomeIcon icon={faShoppingBag} />,
		url: `${boardUrl}/index.php?forums/11`
	},
	{
		name: 'LÀM ĐẸP',
		icon: <FontAwesomeIcon icon={faHatWizard} />,
		url: `${boardUrl}/index.php?forums/12`
	},
];

const CategoryItem = ({name, icon, url}) => {
	return (
		<a href={url} className="flex items-center space-x-2 p-2 border rounded-lg transition duration-200 ease-in-out cursor-pointer hover:bg-gray-100">
			<span>{icon}</span>
			<span className="text-xs text-gray-700">{name}</span>
		</a>
	);
};

const CategorySection = () => {
	const {toggleCategoryListPopup} = useContext(HeaderContext);

	const {i18n, t} = useTranslation();

	return (
		<div className="p-4 bg-white shadow rounded-lg">
			<div className="flex justify-between items-center mb-4 border-b pb-2">
				<h2 className="text-lg font-bold">{t('category_list')}</h2>
				<FontAwesomeIcon
					onClick={toggleCategoryListPopup}
					icon={faTimes}
					className="text-primary text-xl" />
			</div>
			<div className="grid grid-cols-2 gap-4">
				{categories.map((category, index) => (
					<CategoryItem key={index} name={category.name} icon={category.icon} url={category.url} />
				))}
			</div>
		</div>
	);
};

export default CategorySection;
