import React, {useContext} from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

import LazyLoad from 'react-lazyload';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {faRss} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HomeContext} from "../../../context/homeContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const HighlightItem = ({thread}) => {
	const navigate = useNavigate();

	return (
		<div className="rounded-lg shadow">
			<LazyLoad className="w-full aspect-[1.8/2] rounded-t-lg bg-gray-400 cursor-pointer">
				<img className="rounded-t-lg w-full h-full object-cover"
				     onClick={() => navigate(thread.view_url)}
				     src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
				     alt={thread.title} />
			</LazyLoad>

			<div className="py-3 px-2 text-justify">
				<a href={thread.view_url}>
					<h5 className="mb-2 font-semibold text-white line-clamp-3">{thread.title}</h5>
				</a>
			</div>
		</div>
	);
}

const HighlightSection = () => {
	const {i18n, t} = useTranslation();
	const {highlightPosts} = useContext(HomeContext);

	if (highlightPosts && highlightPosts.length > 0) {
		return (
			<div className="bg-primary text-white p-4 rounded-lg">
				<div className="flex items-center mb-4">
					<FontAwesomeIcon icon={faRss} className="text-lg" />
					<h2 className="text-xl font-bold ml-2">{t('dont_miss_out')}</h2>
				</div>

				<Swiper
					className="highlight-section-slide"
					modules={[Pagination, Navigation]}
					spaceBetween={10}
					slidesPerView={1}
					navigation
					pagination={{clickable: true}}
					breakpoints={{
						640: {
							slidesPerView: 2,
						},
						768: {
							slidesPerView: 3,
						},
					}}
				>
					{highlightPosts.map((thread, index) => (
						<SwiperSlide key={index}>
							<HighlightItem thread={thread}/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		);
	}
};

export default HighlightSection;