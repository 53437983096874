import React, { useState, useEffect } from 'react';
import PlaceholderLoading from "react-placeholder-loading";

const PreloadImage = ({ src, alt, className, ...props }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const img = new Image();
		img.src = src;
		img.onload = () => setLoaded(true);
	}, [src]);

	return (
		<>
			{loaded ? (
				<img src={src} alt={alt} className={className} {...props} />
			) : (
				<div className={`placeholder ${className}`}>
					<PlaceholderLoading width={'100%'} height={'100%'} shape={'rect'}/>
				</div>
			)}
		</>
	);
};

export default PreloadImage;