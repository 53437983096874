import React, { useContext } from 'react';
import { ThreadContext, ThreadProvider } from '../../context/threadContext';
import Body from '../common/Body';
import HeaderSection from './section/HeaderSection';
import FirstPostContentSection from './section/FirstPostContentSection';
import { Helmet } from 'react-helmet';
import { generateHeadTags } from 'react-seo-tools';
import RelatedThreadsSection from './section/RelatedThreadsSection';
import TagListSection from './section/TagListSection';
import Breadcrumb from '../common/Breadcrumb';
import ActionButtons from '../common/MemberProfilePost/ActionButtons';
import { convertReactionUsersToReactionData } from '../../utils/user';
import ThreadMagazine from './ThreadMagazine';

const ThreadDisplay = () => {
	const { thread } = useContext(ThreadContext);

	if (thread) {
		if (thread.is_magazine) {
			return <ThreadMagazine />;
		} else {
			return <Body mainContent={<MainContent />} sidebarContent={'default'} />;
		}
	} else {
		return <Body sidebarContent={'default'} />;
	}
};

const MainContent = () => {
	const { thread, firstPost, relatedThreads } = useContext(ThreadContext);

	return (
		<div>
			{(thread && firstPost) && (
				<Helmet>
					{generateHeadTags({
						title: thread.meta_title || thread.title,
						description: thread.meta_description || thread.excerpt,
						openGraph: {
							type: 'article',
							title: thread.title,
							image: thread.meta_image_url || thread.thumbnail_url,
							'article:author': thread.username,
							'article:tag': thread.tags,
							'article:published_time': thread.post_date_meta,
						},
					})}
				</Helmet>
			)}

			{thread && firstPost && (
				<div>
					<section className="mb-4">
						<Breadcrumb items={thread.breadcrumbs} />
					</section>

					<section className="mb-4">
						<HeaderSection thread={thread} firstPost={firstPost} />
					</section>

					<section className="post-content mb-4">
						<FirstPostContentSection thread={thread} firstPost={firstPost} />
					</section>

					<section className="mb-4">
						<ActionButtons
							firstPostId={thread.first_post_id}
							initialReactionsData={convertReactionUsersToReactionData(firstPost.reaction_users)}
							initialReactionScore={Object.values(firstPost.reaction_users).length}
							isReactedTo={firstPost.is_reacted}
							canReact={firstPost.can_react}
							threadViewUrl={thread.view_url}
						/>
					</section>

					<section className="mb-4">
						<TagListSection tags={Object.values(thread.tags)} />
					</section>

					{relatedThreads && (
						<section className="mb-4">
							<RelatedThreadsSection thread={thread} initialRelatedThreads={relatedThreads} />
						</section>
					)}
				</div>
			)}
		</div>
	);
};

const Thread = () => {
	return (
		<ThreadProvider>
			<ThreadDisplay />
		</ThreadProvider>
	);
};

export default Thread;