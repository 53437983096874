import React, {useContext} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {HomeContext} from "../../../context/homeContext";
import LazyLoad from "react-lazyload";
import {useTranslation} from "react-i18next";
import RelativeTime from "../../common/RelativeTime";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const VideoMainItem = ({thread}) => {
	const navigate = useNavigate();

	return (
		<div onClick={() => navigate(thread.view_url)} className="cursor-pointer">
			<LazyLoad className="w-full h-auto rounded-lg bg-gray-400 md:aspect-auto">
				<img className="w-full h-[250px] rounded-lg mb-4 lg:h-[450px] lg:object-cover"
				     src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
				     alt={thread.title}/>
			</LazyLoad>

			<h3 className="text-md font-semibold mb-2 md:text-lg">{thread.title}</h3>
			<p className="text-gray-500 text-sm"><RelativeTime timestamp={thread.post_date} /></p>
		</div>
	);
}

const VideoSideItem = ({thread}) => {
	const navigate = useNavigate();

	return (
		<div onClick={() => navigate(thread.view_url)}
		     className="w-full flex mb-4"
		>
			<LazyLoad className="w-[80px] h-[80px] shrink-0 grow-0 rounded-lg bg-gray-400">
				<img className="w-full h-full rounded-lg object-cover"
				     src={thread.thumbnail_url} alt={thread.title}/>
			</LazyLoad>

			<div className="min-w-0 pl-2">
				<h4 className="text-md font-semibold line-clamp-3">{thread.title}</h4>
				<p className="text-gray-500 text-sm">{thread.post_date}</p>
			</div>
		</div>
	);
}

const VideoSection = () => {
	const { i18n, t } = useTranslation();

	const {
		videoPosts, hasMoreVideoPosts, loadMoreVideoPosts
	} = useContext(HomeContext);

	if (videoPosts && videoPosts.length > 0) {
		return (
			<div className="bg-gray-900 text-white p-4 md:rounded-lg">
				<div className="flex items-center mb-4">
					<FontAwesomeIcon icon={faYoutube} className="text-primary"/>
					<h4 className="text-xl font-bold ml-2">VIDEO</h4>
				</div>

				<div className="flex flex-col lg:flex-row">
					<div className="video-main-item p-2 lg:w-2/3">
						<VideoMainItem thread={videoPosts[0]} />
					</div>

					<div className="video-side-list w-full max-h-[250px] overflow-y-auto p-2 lg:block lg:w-1/3 lg:max-h-[600px]">
						{videoPosts.map((video, index) => {
							if (index > 0) {
								return (
									<VideoSideItem key={index} thread={video} />
								)
							}
						})}

						{hasMoreVideoPosts && (
							<div className="text-center mt-4">
								<button onClick={loadMoreVideoPosts}
								        className="w-full px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
									{t('load_more')}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
};

export default VideoSection;
