import React, {useContext} from 'react';
import {AuthContext} from "../../../context/authContext";
import ActionButtons from "./ActionButtons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const MemberProfilePost = ({
	                           firstPostId = null,
	                           title,
	                           initialReactionsData = null,
	                           initialReactionScore = 0,
	                           isReactedTo = null,
	                           date = null,
	                           thumbnailUrl,
	                           threadViewUrl,
	                           excerpt = null,
                           }) => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const { visitor } = useContext(AuthContext);

	return (
		<div className="group py-4 px-2 bg-white mb-4 w-full border-y border-gray-200 md:rounded-lg md:border md:shadow-none md:p-4">
			<div className="mb-4">
				<div className="block font-medium text-base leading-snug text-primary">
					<span onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')} className="inline font-bold cursor-pointer">{title}</span>
				</div>

				<span onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')} className="block text-sm text-gray-500 font-light leading-snug cursor-pointer">{date}</span>
			</div>

			<div className="text-gray-800 leading-snug md:leading-normal">
				<p className="line-clamp-3">{excerpt}</p>
				<span onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')} className="inline show-more cursor-pointer text-primary font-semibold hover:text-red-900">{t('view_more')}</span>

				<div onClick={() => navigate(threadViewUrl ? threadViewUrl : '#')}  className="mt-2 text-center cursor-pointer">
					<span className="block w-full">
						<img src={thumbnailUrl} alt={title} className="w-full h-auto rounded-lg"/>
					</span>
				</div>
			</div>

			{visitor && (
				<ActionButtons
					firstPostId={firstPostId}
					initialReactionsData={initialReactionsData}
					initialReactionScore={initialReactionScore}
					isReactedTo={isReactedTo}
					threadViewUrl={threadViewUrl}
				/>
			)}
		</div>
	);
}

export default MemberProfilePost;
