import Node from "./Node";
import {useNavigate} from "react-router-dom";

const Category = ({nodes, node, childNodeIds = []}) => {
	const navigate = useNavigate();

	if (nodes && node) {
		return (
			<div className="node-category mb-4 rounded-lg border border-borderColor overflow-hidden">
				<span className="u-anchorTarget" id={node.view_url}></span>

				<div className="node-container">
					<div className="bg-primary text-white text-xl py-2 px-4 border-b border-primary">
						{node.title}
					</div>

					{(childNodeIds && childNodeIds.length > 0) && (
						<div className="node-body">
							{childNodeIds.map((id) => (
								<Node key={id} node={nodes[id]} />
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Category;