import React, { useCallback, useContext, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faShare } from "@fortawesome/free-solid-svg-icons";
import { ReactionBarSelector, ReactionCounter } from '@charkour/react-reactions';
import { ControlledMenu, useHover, useMenuState } from "@szhsin/react-menu";
import axios from 'axios';
import qs from 'qs';
import { API_BASE_URL, API_KEY, reaction, reactionIconSize, reactions } from '../../../config/config';
import { AuthContext } from '../../../context/authContext';
import { convertReactionUsersToReactionData } from '../../../utils/user';
import { openPopupWindow } from "../../../utils/utils";

const ActionButtons = ({
    firstPostId,
    initialReactionsData,
    initialReactionScore,
    isReactedTo,
	canReact = false,
    threadViewUrl
}) => {
	const { visitor } = useContext(AuthContext);

	const [reactedId, setReactedId] = useState(isReactedTo);
	const [reactionScore, setReactionScore] = useState(initialReactionScore);
	const [reactionsData, setReactionsData] = useState(initialReactionsData);

	const ref = useRef(null);
	const [menuState, toggle] = useMenuState({ transition: true });
	const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

	const handleReactionButton = useCallback((reactionId) => {
		reactToPost(reactionId);
	}, [firstPostId, visitor.user_id]);

	const reactToPost = useCallback((reactionId) => {
		if (!firstPostId) {
			return;
		}

		let data = qs.stringify({
			'reaction_id': reactionId
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${API_BASE_URL}/posts/${firstPostId}/react`,
			headers: {
				'XF-Api-Key': API_KEY,
				'XF-Api-User': visitor.user_id,
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: data,
			withCredentials: true
		};

		axios.request(config)
			.then((response) => {
				let action = response.data.action;
				if (action === 'insert') {
					setReactedId(reactionId);
				} else {
					setReactedId(null);
				}
				fetchReactionsData();
			})
			.catch((error) => {
				console.error('Failed to react to post:', error);
			});
	}, [firstPostId, visitor.user_id]);

	const fetchReactionsData = useCallback(() => {
		if (!firstPostId) {
			return;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${API_BASE_URL}/posts/${firstPostId}`,
			headers: {
				'XF-Api-Key': API_KEY,
				'XF-Api-User': visitor.user_id,
			},
			withCredentials: true
		};

		axios.request(config)
			.then((response) => {
				let post = response.data.post;
				if (post) {
					setReactionScore(Object.values(post.reaction_users).length);
					setReactionsData(convertReactionUsersToReactionData(Object.values(post.reaction_users)));
				}
			})
			.catch((error) => {
				console.error('Failed to fetch reactions data:', error);
			});
	}, [firstPostId, visitor.user_id]);

	const handleShareButton = () => openPopupWindow(`https://www.facebook.com/sharer/sharer.php?u=${threadViewUrl}`);

	return (
		<div>
			{(visitor && reactionScore > 0) && (
				<div className="mt-4 border-y border-gray-200 py-2 text-gray-500">
					<ReactionCounter reactions={reactionsData} user={visitor.username} showOthersAlways={false} />
				</div>
			)}

			<div className="flex justify-between items-center mt-4">
				<div className="text-gray-500">
					<ControlledMenu {...hoverProps} {...menuState} anchorRef={ref} onClose={() => toggle(false)}
					                className="z-10">
						<ReactionBarSelector reactions={reactions}
						                     iconSize={reactionIconSize}
						                     onSelect={handleReactionButton}
						                     showOthersAlways={false}
						/>
					</ControlledMenu>

					{canReact === true ? (
						<div className="reaction-button cursor-pointer flex items-center" ref={ref} {...anchorProps}>
							{reactedId === null ? (
								<FontAwesomeIcon icon={faThumbsUp} className="align-top" />
							) : (
								<div className="w-[18px] h-[18px]">{reaction(reactedId).node}</div>
							)}

							{reactedId === null ? (
								<span className="ml-1 text-gray-500">{reactionScore}</span>
							) : (
								<b className="ml-1">{reaction(reactedId).label}</b>
							)}
						</div>
					) : (
						<div className="reaction-button">
							<FontAwesomeIcon icon={faThumbsUp} className="align-top"/>
							<span className="ml-1 text-gray-500">{reactionScore}</span>
						</div>
					)}
				</div>

				<div className="text-gray-500 font-light cursor-pointer transition hover:text-gray-800"
				     onClick={handleShareButton}>

					<FontAwesomeIcon icon={faShare} className="p-0.5"/>
					<span className="ml-1 text-gray-500 font-light">Share</span>
				</div>
			</div>
		</div>
	);
}

export default ActionButtons;
