import React, { createContext, useContext } from 'react';
import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL, API_KEY } from "../config/config";
import { AuthContext } from "./authContext";
import {isMobile} from "../utils/utils";

const HomeContext = createContext(null);

const queryClient = new QueryClient();

const fetchFeaturedPosts = async ({ queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/featured-posts?limit=5`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data.features;
};

const fetchMainstreamPosts = async ({ pageParam = 1, queryKey }) => {
	const [_, { visitor }] = queryKey;
	const limit = isMobile() ? 5 : 20;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/mainstream-posts?page=${pageParam}&limit=${limit}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchVideoPosts = async ({ pageParam = 1, queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/video-posts?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchReviewPosts = async ({ pageParam = 1, queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/review-posts?limit=6&page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchDiscussionPosts = async ({ pageParam = 1, queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/discussion-posts?limit=4&page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchSuggestedPosts = async ({ pageParam = 1, queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/suggested-posts?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data;
};

const fetchHighlightPosts = async ({ queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/highlight-posts`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data.threads;
};

const fetchSpecialPosts = async ({ queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/special-posts`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data.threads;
};

const fetchTrendings = async ({ queryKey }) => {
	const [_, { visitor }] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/react-home/trending-posts`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};
	const response = await axios.request(config);
	return response.data.trendings;
};

const HomeProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);

	const { data: featuredPosts } = useQuery({
		queryKey: ['featuredPosts', { visitor }],
		queryFn: fetchFeaturedPosts,
		enabled: !!visitor,
	});

	const {
		data: mainstreamData,
		fetchNextPage: loadMoreMainstreamPosts,
		hasNextPage: hasMoreMainstreamPosts,
	} = useInfiniteQuery({
		queryKey: ['mainstreamPosts', { visitor }],
		queryFn: fetchMainstreamPosts,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor,
	});

	const {
		data: videoData,
		fetchNextPage: loadMoreVideoPosts,
		hasNextPage: hasMoreVideoPosts,
	} = useInfiniteQuery({
		queryKey: ['videoPosts', { visitor }],
		queryFn: fetchVideoPosts,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor,
	});

	const {
		data: reviewData,
		fetchNextPage: loadMoreReviewPosts,
		hasNextPage: hasMoreReviewPosts,
	} = useInfiniteQuery({
		queryKey: ['reviewPosts', { visitor }],
		queryFn: fetchReviewPosts,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor,
	});

	const {
		data: discussionData,
		fetchNextPage: loadMoreDiscussionPosts,
		hasNextPage: hasMoreDiscussionPosts,
	} = useInfiniteQuery({
		queryKey: ['discussionPosts', { visitor }],
		queryFn: fetchDiscussionPosts,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor,
	});

	const {
		data: suggestedData,
		fetchNextPage: loadMoreSuggestedPosts,
		hasNextPage: hasMoreSuggestedPosts,
	} = useInfiniteQuery({
		queryKey: ['suggestedPosts', { visitor }],
		queryFn: fetchSuggestedPosts,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor,
	});

	const { data: highlightPosts } = useQuery({
		queryKey: ['highlightPosts', { visitor }],
		queryFn: fetchHighlightPosts,
		enabled: !!visitor,
	});

	const { data: specialPosts } = useQuery({
		queryKey: ['specialPosts', { visitor }],
		queryFn: fetchSpecialPosts,
		enabled: !!visitor,
	});

	const { data: trendings } = useQuery({
		queryKey: ['trendings', { visitor }],
		queryFn: fetchTrendings,
		enabled: !!visitor,
	});

	const mainstreamPosts = mainstreamData?.pages.flatMap(page => page.entries) || [];
	const videoPosts = videoData?.pages.flatMap(page => page.threads) || [];
	const reviewPosts = reviewData?.pages.flatMap(page => page.threads) || [];
	const discussionPosts = discussionData?.pages.flatMap(page => page.threads) || [];
	const suggestedPosts = suggestedData?.pages.flatMap(page => page.threads) || [];

	return (
		<HomeContext.Provider
			value={{
				featuredPosts,
				mainstreamPosts, hasMoreMainstreamPosts, loadMoreMainstreamPosts,
				videoPosts, hasMoreVideoPosts, loadMoreVideoPosts,
				reviewPosts, hasMoreReviewPosts, loadMoreReviewPosts,
				discussionPosts, hasMoreDiscussionPosts, loadMoreDiscussionPosts,
				suggestedPosts, hasMoreSuggestedPosts, loadMoreSuggestedPosts,
				highlightPosts,
				specialPosts,
				trendings,
			}}
		>
			{children}
		</HomeContext.Provider>
	);
};

const HomeContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<HomeProvider>{children}</HomeProvider>
	</QueryClientProvider>
);

export { HomeContext, HomeContextProvider as HomeProvider };