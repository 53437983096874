import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import {appUrl} from "./config/config";

i18n
	.use(i18nBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: "vi",
		lng: "vi",
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: `${appUrl}/translations/{{lng}}.json`,
		},
	});

export default i18n;