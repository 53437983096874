import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {boardUrl} from "../../../config/config";
import {useNavigate} from "react-router-dom";

const FloatingButtonSection = ({forum = null}) => {
	const postThreadUrl = forum ? `${forum.view_url}?post_thread=true` : `post-thread`;
	const navigate = useNavigate();

	return (
		<div className="fixed bottom-24 right-4">
			<button
				onClick={() => navigate(postThreadUrl)}
				className="flex justify-center items-center bg-primary text-white font-bold py-2 px-4 w-12 h-12 rounded-full shadow-lg transition hover:bg-red-900"
			>
				<FontAwesomeIcon icon={faPlus} />
			</button>
		</div>
	);
}

export default FloatingButtonSection;