import React, {useContext} from 'react';
import {MemberContext} from "../../../context/memberContext";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingNoMoreContent from "../../common/LoadingNoMoreContent";
import LoadingContent from "../../common/LoadingContent";
import {convertReactionUsersToReactionData} from "../../../utils/user";
import MemberProfilePost from "../../common/MemberProfilePost/MemberProfilePost";
import RelativeTime from "../../common/RelativeTime";
import {isMobile} from "../../../utils/utils";

const ThreadListSection = () => {
	const {
		threads, threadsLoading, hasMoreThreads, loadMoreThreads
	} = useContext(MemberContext);

	if (threads) {
		if (!threadsLoading) {
			return (
				<div className="-mx-4 md:-mx-0 md:border-0">
					<InfiniteScroll
						next={loadMoreThreads}
						hasMore={hasMoreThreads}
						loader={<LoadingContent/>}
						dataLength={threads.length}
						endMessage={<LoadingNoMoreContent/>}
					>
						{threads.map((thread, index) =>
							<div key={index}>
								<MemberProfilePost firstPostId={thread.first_post_id}
								                   title={thread.title}
								                   category={thread.Forum.title}
								                   canReact={thread.FirstPost.can_react}
								                   initialReactionsData={convertReactionUsersToReactionData(Object.values(thread.FirstPost.reaction_users))}
								                   initialReactionScore={Object.values(thread.FirstPost.reaction_users).length}
								                   isReactedTo={thread.FirstPost.is_reacted}
								                   author={thread.username}
								                   authorAvatarUrl={thread.User && thread.User.avatar_urls.s}
								                   date={<RelativeTime timestamp={thread.post_date} />}
								                   thumbnailUrl={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
								                   threadViewUrl={thread.view_url}
								                   authorViewUrl={thread.User ? thread.User.view_url : null}
								                   categoryViewUrl={thread.Forum.view_url}
								                   excerpt={thread.excerpt}
								/>
							</div>
						)}
					</InfiniteScroll>
				</div>
			);
		} else {
			return <LoadingContent/>
		}
	} else {
		return <LoadingNoMoreContent/>
	}
}

export default ThreadListSection;