import React from 'react';
import HtmlParsedText from "../../common/HtmlParsedText";
import {useTranslation} from "react-i18next";
import { Tooltip } from 'react-tooltip'
import {copyToClipboard} from "../../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";

const FirstPostContentSection = ({thread, firstPost}) => {
	const { t } = useTranslation();

	if (thread && firstPost) {
		return <div className="max-w-full overflow-x-hidden">
			<HtmlParsedText text={firstPost.message_parsed} />

			{thread.custom_fields.source && (
				<div className="mt-4 text-right">
					<Tooltip id="thread-source-tooltip" className="z-50" />

					<div className="cursor-pointer inline-flex items-center px-3 py-1.5 text-sm text-gray-600 bg-gray-200 font-medium text-center rounded-lg"
					     data-tooltip-id="thread-source-tooltip"
					     data-tooltip-content={`${t('click_to_copy')} ${t('source_url')}`}
					     onClick={() => copyToClipboard(thread.custom_fields.source_url)}
					>
						{t('source')}: <span className="inline-block ml-1 font-bold">{thread.custom_fields.source}</span>
						<FontAwesomeIcon icon={faExternalLink} className="ml-0.5" />
					</div>
				</div>
			)}
		</div>
	}
}

export default FirstPostContentSection;