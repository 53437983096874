import React, {useContext} from 'react';
import {SearchContext, SearchProvider} from "../../context/searchContext";
import Body from "../common/Body";
import {generateHeadTags} from "react-seo-tools";
import {Helmet} from "react-helmet";
import ResultSection from "./section/ResultSection";
import {useTranslation} from "react-i18next";
import {boardUrl} from "../../config/config";
import SearchBar from "../common/SearchBar";

const SearchQueryEmpty = () => {
	const {i18n, t} = useTranslation();

	return (
		<section className="flex flex-col items-center justify-center min-h-[60vh]">
			<div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
				<div className="mx-auto max-w-screen-sm text-center">
					<h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">{t('search_something')}</h1>

					<div className="mb-4 w-full">
						<SearchBar />
					</div>

					<a href={boardUrl}
					   className="inline-flex text-white bg-primary hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
						{t('back_to_homepage')}
					</a>
				</div>
			</div>
		</section>
	);
}

const MainContent = () => {
	const {i18n, t} = useTranslation();
	const {searchQuery} = useContext(SearchContext);

	return (
		<div>
			{searchQuery ? (
				<Helmet>
					{generateHeadTags({
						title: searchQuery,
						description: '',
						openGraph: {
							type: 'website',
							title: searchQuery,
							image: '',
						},
					})}
				</Helmet>
			) : (
				<Helmet>
					{generateHeadTags({
						title: t('search_something'),
						description: '',
						openGraph: {
							type: 'website',
							title: searchQuery,
							image: '',
						},
					})}
				</Helmet>
			)}

			{searchQuery ? (
				<div>
					<section className="mb-4">
						<h1 className="text-xl font-bold md:text-3xl">{searchQuery}</h1>
					</section>

					<section className="mb-4">
						<ResultSection/>
					</section>
				</div>
			) : <SearchQueryEmpty />}
		</div>
	);
}

const Search = () => {
	return (
		<SearchProvider>
			<Body mainContent={<MainContent/>}
			      sidebarContent={'default'} />
		</SearchProvider>
	)
}

export default Search;