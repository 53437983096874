import React, {useEffect, useRef, useState} from 'react';
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FloatingActionButtons = () => {
	const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
	const prevScrollPos = useRef(0);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		const toggleVisibility = () => {
			const currentScrollPos = window.pageYOffset;

			// Button is displayed after scrolling for 500 pixels
			if (currentScrollPos > 500 && currentScrollPos > prevScrollPos.current) {
				setIsScrollToTopVisible(true);
			} else {
				setIsScrollToTopVisible(false);
			}

			prevScrollPos.current = currentScrollPos;
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, [isScrollToTopVisible]);

	return (
		<div className="fixed bottom-4 right-4">
			{isScrollToTopVisible && (
				<button onClick={scrollToTop}
				        className="bg-primary text-white font-bold py-2 px-4 w-12 h-12 rounded-full shadow-lg transition hover:bg-red-900">
					<FontAwesomeIcon icon={faChevronUp}/>
				</button>
			)}
		</div>
	);
}

export default FloatingActionButtons;