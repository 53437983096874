import {createContext, useContext} from "react";
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query";
import {API_BASE_URL, API_KEY} from "../config/config";
import axios from "axios";
import {AuthContext} from "./authContext";

export const ForumListContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchNodeList = async ({ queryKey }) => {
	const [_, {visitor}] = queryKey;

	const config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/nodes`,
		headers: {
			"XF-Api-Key": API_KEY,
			"XF-Api-User": visitor.user_id,
		},
		withCredentials: true,
	}

	const response = await axios.request(config);
	const data = response.data;
	const nodeTree = data.tree_map;
	const nodes = data.nodes;

	const nodesArray = nodes.reduce((acc, node) => {
		acc[node.node_id] = node;
		return acc;
	}, {});

	return {
		nodeTree: nodeTree,
		nodes: nodesArray
	}
}

export const ForumListProvider = ({ children }) => {
	const {visitor} = useContext(AuthContext);

	const { data: nodeList, isLoading: nodeListLoading, error: nodeListError } = useQuery({
		queryKey: ['nodeList', {visitor}],
		queryFn: fetchNodeList,
		enabled: !!visitor,
	});

	return (
		<ForumListContext.Provider value={{
			nodeList, nodeListLoading, nodeListError
		}}>
			{children}
		</ForumListContext.Provider>
	);
}

export const ForumListContextProvider = ({ children }) => {
	<QueryClientProvider client={queryClient}>
		<ForumListProvider>{children}</ForumListProvider>
	</QueryClientProvider>
}