import reactionLike from "../static/reaction_like.svg";
import reactionLove from "../static/reaction_love.svg";
import reactionHaha from "../static/reaction_haha.svg";
import reactionWow from "../static/reaction_wow.svg";
import reactionSad from "../static/reaction_sad.svg";
import reactionAngry from "../static/reaction_angry.svg";
import logo from "../static/logo.png";
import logoOg from "../static/logo.og.png";
import {getCurrentBaseUrl} from "../utils/utils";

export const development = getCurrentBaseUrl() === 'http://localhost:3000';
export const boardTitle = 'Golive.vn';
export const boardUrl = (development) ? 'http://localhost/golive' : 'https://golive.vn';
export const appUrl = (development) ? 'http://localhost:3000' : 'https://golive.vn';
export const logoUrl = logo;
export const logoOgUrl = logoOg;

export const sidebarAds1 = 'https://i.imgur.com/7xKlbT1.png';
export const sidebarAds2 = 'https://i.imgur.com/OIsGuuo.png';

export const footerLogoUrl = 'https://i.imgur.com/8Cq6IIQ.png';

// API
export const API_KEY = 'IMuX4EIk2ZzDYF2jSyiaGrn4osR-aAIb';
export const API_BASE_URL = (development) ? 'http://localhost/golive/api' : 'https://golive.vn/api';
export const VALIDATED_USER_GROUP_ID = 5;

// Reactions
export const reactionIconSize = 48;
export const reaction = (reactionId) => {
	switch (reactionId) {
		case 1:
			return {
				node: <div className="reaction-icon">
					<img src={reactionLike} height={reactionIconSize} alt="Like"/>
				</div>,
				label: 'Like'
			};
		case 2:
			return {
				node: <div className="reaction-icon">
					<img src={reactionLove} height={reactionIconSize} alt="Love"/>
				</div>,
				label: 'Love'
			};
		case 3:
			return {
				node: <div className="reaction-icon">
					<img src={reactionHaha} height={reactionIconSize} alt="Haha"/>
				</div>,
				label: 'Haha'
			};
		case 4:
			return {
				node: <div className="reaction-icon">
					<img src={reactionWow} height={reactionIconSize} alt="Wow"/>
				</div>,
				label: 'Wow'
			};
		case 5:
			return {
				node: <div className="reaction-icon">
					<img src={reactionSad} height={reactionIconSize} alt="Sad"/>
				</div>,
				label: 'Sad'
			};
		case 6:
			return {
				node: <div className="reaction-icon">
					<img src={reactionAngry} height={reactionIconSize} alt="Angry"/>
				</div>,
				label: 'Angry'
			};
	}
}

export const reactions = [
	{
		label: "Like",
		node: reaction(1).node,
		key: 1
	},
	{
		label: "Love",
		node: reaction(2).node,
		key: 2
	},
	{
		label: "Haha",
		node: reaction(3).node,
		key: 3
	},
	{
		label: "Wow",
		node: reaction(4).node,
		key: 4
	},
	{
		label: "Sad",
		node: reaction(5).node,
		key: 5
	},
	{
		label: "Angry",
		node: reaction(6).node,
		key: 6
	}
]