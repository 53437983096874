import React, {useContext} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HomeContext} from "../../../context/homeContext";
import LazyLoad from "react-lazyload";
import {useTranslation} from "react-i18next";
import SectionHeader from "../../common/SectionHeader";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const DiscussionItem = ({thread}) => {
	const navigate = useNavigate();

	return (
		<div className="flex">
			<LazyLoad
				className="w-1/2 h-auto aspect-[3/2] overflow-hidden rounded-lg bg-gray-400 flex-grow-0 flex-shrink-0 cursor-pointer">
				<img src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
				     onClick={() => navigate(thread.view_url)}
				     alt={thread.title}
				     className="w-full h-full object-cover rounded-lg"/>
			</LazyLoad>

			<div className="min-w-0 pl-2">
				<h4 className="text-md font-semibold mb-2 line-clamp-3 cursor-pointer hover:text-primary">
					<span onClick={() => navigate(thread.view_url)} title={thread.title}>{thread.title}</span>
				</h4>

				<div className="text-sm line-clamp-2">{thread.excerpt}</div>
			</div>
		</div>
	);
};

const DiscussionSection = () => {
	const {i18n, t} = useTranslation();

	const {
		discussionPosts, hasMoreDiscussionPosts, loadMoreDiscussionPosts,
	} = useContext(HomeContext);

	if (discussionPosts && discussionPosts.length > 0) {
		return (
			<div>
				<div className="">
					<SectionHeader title={t('may_be_you_wanna_read')} icon={<FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2"/>} />
				</div>

				<div className="grid grid-cols-0 gap-4 md:grid-cols-2">
					{discussionPosts.map((thread, index) => (
						<DiscussionItem key={index} thread={thread} />
					))}
				</div>

				{hasMoreDiscussionPosts && (
					<div className="text-center mt-4">
						<button onClick={loadMoreDiscussionPosts}
						        className="inline-block w-auto mx-auto px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
							{t('load_more')}
						</button>
					</div>
				)}
			</div>
		);
	}
};

export default DiscussionSection;
