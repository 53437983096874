import React, {useContext, useEffect, useState} from 'react';
import {API_BASE_URL, API_KEY} from "../../../config/config";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import MainstreamPost from "../../common/MainstreamPost";
import {faChartLine, faComments} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ThreadContext} from "../../../context/threadContext";
import LoadingContent from "../../common/LoadingContent";
import LoadingNoMoreContent from "../../common/LoadingNoMoreContent";
import {useTranslation} from "react-i18next";
import SectionHeader from "../../common/SectionHeader";
import RelativeTime from "../../common/RelativeTime";

const RelatedThreadsSection = () => {
	const { i18n, t } = useTranslation();
	const { relatedThreads, hasMoreRelatedThreads, loadMoreRelatedThreads } = useContext(ThreadContext);

	if (relatedThreads && relatedThreads.length > 0) {
		return (
			<div>
				<SectionHeader title={t('related_threads')} icon={<FontAwesomeIcon icon={faChartLine} className="mr-2"/>} />

				<InfiniteScroll
					next={loadMoreRelatedThreads}
					hasMore={hasMoreRelatedThreads}
					loader={<LoadingContent />}
					dataLength={relatedThreads.length}
					endMessage={<LoadingNoMoreContent />}
				>
					{relatedThreads.map((thread, index) => {
						return (
							<div key={index}>
								<MainstreamPost title={thread.title}
								                category={thread.Forum.title}
								                author={thread.username}
								                date={<RelativeTime timestamp={thread.post_date} />}
								                thumbnailUrl={thread.thumbnail_url}
								                threadViewUrl={thread.view_url}
								                authorViewUrl={thread.User ? thread.User.view_url : null}
								                categoryViewUrl={thread.Forum.view_url}
								                excerpt={thread.excerpt} />
							</div>
						);
					})}
				</InfiniteScroll>
			</div>
		);
	}
}

export default RelatedThreadsSection;