import EmbedTool from '@editorjs/embed';

const supportedPlatforms = [
	'Facebook', 'Youtube'
];

export default class Embed extends EmbedTool {
	static get toolbox() {
		return {
			title: 'Embed',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M608 0H160a32 32 0 0 0 -32 32v96h160V64h192v320h128a32 32 0 0 0 32-32V32a32 32 0 0 0 -32-32zM232 103a9 9 0 0 1 -9 9h-30a9 9 0 0 1 -9-9V73a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm352 208a9 9 0 0 1 -9 9h-30a9 9 0 0 1 -9-9v-30a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm0-104a9 9 0 0 1 -9 9h-30a9 9 0 0 1 -9-9v-30a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm0-104a9 9 0 0 1 -9 9h-30a9 9 0 0 1 -9-9V73a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm-168 57H32a32 32 0 0 0 -32 32v288a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V192a32 32 0 0 0 -32-32zM96 224a32 32 0 1 1 -32 32 32 32 0 0 1 32-32zm288 224H64v-32l64-64 32 32 128-128 96 96z"/></svg>'
		};
	}

	/**
	 * Render Embed tool content
	 *
	 * @returns {HTMLElement}
	 */
	render() {
		if (!this.data.service) {
			const container = document.createElement('div');

			this.element = container;
			const input = document.createElement('input');
			input.classList.add('cdx-input');
			input.placeholder = `Supports: ${supportedPlatforms.join(', ')} videos`;
			input.type = 'url';
			input.addEventListener('paste', (event) => {
				const url = event.clipboardData.getData('text');
				const service = Object.keys(Embed.services).find((key) => Embed.services[key].regex.test(url));
				if (service) {
					this.onPaste({detail: {key: service, data: url}});
				}
			});
			container.appendChild(input);

			return container;
		}
		return super.render();
	}

	validate(savedData) {
		return !!(savedData.service && savedData.source);
	}
}