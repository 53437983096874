import {useTranslation} from "react-i18next";

const LoadingNoMoreContent = () => {
	const { i18n, t } = useTranslation();

	return (
		<div className="loading-no-more-content text-center font-semibold mt-2">
			{t('no_more_content')}
		</div>
	);
}

export default LoadingNoMoreContent;