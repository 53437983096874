import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {HeaderContext} from "../../context/headerContext";
import MainstreamPost from "./MainstreamPost";
import SectionHeader from "./SectionHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons";
import RelativeTime from "./RelativeTime";

const LatestNews = () => {
	const { i18n, t } = useTranslation();

	const {
		latestNewsPosts, latestNewsPostsLoading, hasMoreLatestNewsPosts, loadMoreLatestNewsPosts
	} = useContext(HeaderContext);

	if (latestNewsPosts && latestNewsPosts.length > 0) {
		return (
			<div className="p-4">
				<SectionHeader title={t('latest_news')} icon={<FontAwesomeIcon icon={faNewspaper} className="mr-2" />} />

				{latestNewsPosts.map((thread, index) => {
					return (
						<MainstreamPost
							key={index}
							title={thread.title}
			                category={thread.Forum.title}
			                author={thread.username}
			                date={<RelativeTime timestamp={thread.post_date} />}
			                thumbnailUrl={thread.thumbnail_url}
			                threadViewUrl={thread.view_url}
			                authorViewUrl={thread.User ? thread.User.view_url : null}
			                categoryViewUrl={thread.Forum.view_url}
			                excerpt={thread.excerpt}
						/>
					);
				})}

				{hasMoreLatestNewsPosts && (
					<div className="text-center mt-4">
						<button onClick={loadMoreLatestNewsPosts}
						        className="w-full px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
							{latestNewsPostsLoading ? t('loading_content') : t('load_more')}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default LatestNews;