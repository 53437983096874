import React, {useContext} from 'react';
import MainstreamPost from "../../common/MainstreamPost";
import {useTranslation} from "react-i18next";
import {ForumContext} from "../../../context/forumContext";
import RelativeTime from "../../common/RelativeTime";
import {isMobile} from "../../../utils/utils";

const ThreadListSection = () => {
	const { i18n, t } = useTranslation();

	const {
		threads, threadsLoading, hasMoreThreads, loadMoreThreads,
		forum
	} = useContext(ForumContext);

	if (forum && threads && threads.length > 0) {
		return (
			<div>
				{threads.map((thread, index) => {
					return (
						<div key={index}>
							<MainstreamPost
								title={thread.title}
								author={thread.username}
								date={<RelativeTime timestamp={thread.post_date} />}
								thumbnailUrl={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
								threadViewUrl={thread.view_url}
								authorViewUrl={thread.User ? thread.User.view_url : null}
								excerpt={thread.excerpt} />
						</div>
					);
				})}

				{hasMoreThreads && (
					<div className="text-center mt-4">
						<button onClick={loadMoreThreads}
						        className="w-auto px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
							{threadsLoading ? t('loading_content') : t('load_more')}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default ThreadListSection;