import React, { useContext } from 'react';
import {MemberContext, MemberProvider} from "../../context/memberContext";
import Body from "../common/Body";
import {Helmet} from "react-helmet";
import {generateHeadTags} from "react-seo-tools";
import HeroSection from "./section/HeroSection";
import SidenavSection from "./section/SidenavSection";
import ThreadListSection from "./section/ThreadListSection";

const HeroContent = () => {
	const { user } = useContext(MemberContext);

	if (user) {
		return <HeroSection user={user} />
	}
}

const MainContent = () => {
	const { user } = useContext(MemberContext);

	return (
		<div>
			{user && (
				<Helmet>
					{generateHeadTags({
						title: user.username,
						description: user.user_title,
						openGraph: {
							type: 'profile',
							title: user.username,
							image: user.avatar_urls.o,
							'profile:username': user.username
						},
					})}
				</Helmet>
			)}

			{user && (
				<div>
					<section className="mb-4">
						<ThreadListSection />
					</section>
				</div>
			)}
		</div>
	);
}

const Member = () => {
	return (
		<MemberProvider>
			<Body mainContent={<MainContent/>}
			      sidebarContent={'default'}
			      sidenavContent={<SidenavSection />}
			      heroContent={<HeroContent/>}
			/>
		</MemberProvider>
	);
};

export default Member;
