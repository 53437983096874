import React from 'react';

const SideBlock = ({title, children, lastChild = false}) => {
	return (
		<div className={`border-y border-gray-200 p-4 ${!lastChild && 'mb-4'} -mx-4 md:-mx-0 md:rounded-lg md:border-x`}>
			<h3 className="text-md font-bold mb-4 lg:text-xl">{title}</h3>

			{children}
		</div>
	);
}

export default SideBlock;