import React, {useContext} from 'react';
import {TagContext, TagProvider} from "../../context/tagContext";
import Body from "../common/Body";
import {Helmet} from "react-helmet";
import {generateHeadTags} from "react-seo-tools";
import ResultSection from "./section/ResultSection";

const MainContent = () => {
	const {tag} = useContext(TagContext);

	return (
		<div>
			{tag && (
				<Helmet>
					{generateHeadTags({
						title: tag.tag,
						description: '',
						openGraph: {
							type: 'website',
							title: tag.tag,
							image: '',
						},
					})}
				</Helmet>
			)}

			{tag && (
				<div>
					<section className="mb-4">
						<h1 className="text-xl font-bold md:text-3xl">{tag.tag}</h1>
					</section>

					<section className="mb-4">
						<ResultSection/>
					</section>
				</div>
			)}
		</div>
	);
}

const Tag = () => {
	return (
		<TagProvider>
			<Body mainContent={<MainContent/>} sidebarContent={'default'} />
		</TagProvider>
	)
}

export default Tag;