import {createContext, useContext} from "react";
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query";
import {API_BASE_URL, API_KEY} from "../config/config";
import axios from "axios";
import {AuthContext} from "./authContext";

const FooterContext = createContext(null);

const queryClient = new QueryClient();

const fetchFooterColumns = async ({ queryKey }) => {
	const [_, {visitor}] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/footer/columns`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true
	};
	const response = await axios.request(config);
	return response.data.columns;
};

const FooterProvider = ({ children }) => {
	const {visitor} = useContext(AuthContext);

	const { data: footerColumns } = useQuery({
		queryKey: ['footerColumns', {visitor}],
		queryFn: fetchFooterColumns,
		enabled: !!visitor,
	});

	return (
		<FooterContext.Provider
			value={{
				footerColumns
			}}
		>
			{children}
		</FooterContext.Provider>
	);
}

const FooterContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<FooterProvider>{children}</FooterProvider>
	</QueryClientProvider>
);

export {FooterContext, FooterContextProvider as FooterProvider};