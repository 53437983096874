import React from 'react';
import {faAngleRight, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

const BreadcrumbItem = ({item, position}) => {
	const navigate = useNavigate();

	return (
		<li className="inline-flex items-center">
			<button onClick={() => navigate(item.href)}
			   className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary">

				{position === 0 ? (
					<FontAwesomeIcon icon={faHome} className="mr-2" />
				) : (
					<FontAwesomeIcon icon={faAngleRight} className="mr-2" />
				)}

				{item.value}
			</button>
		</li>
	);
}

const Breadcrumb = ({items}) => {
	return (
		<nav className="flex" aria-label="Breadcrumb">
			<ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
				{items.map((item, index) => <BreadcrumbItem key={index} item={item} position={index}/>)}
			</ol>
		</nav>

	);
}

export default Breadcrumb;