import React, {useContext} from 'react';

import LazyLoad from 'react-lazyload';

import {HomeContext} from "../../../context/homeContext";
import MainstreamPost from "../../common/MainstreamPost";
import RelativeTime from "../../common/RelativeTime";
import {useNavigate} from "react-router-dom";
import PlaceholderLoading from "react-placeholder-loading";
import PreloadImage from "../../common/PreloadImage";
import {isMobile} from "../../../utils/utils";

const FirstPost = ({post = null}) => {
	const navigate = useNavigate();

	if (post) {
		return (
			<div className="first-post mb-4">
				<div className="w-full h-auto aspect-[3/1.5] rounded-lg bg-gray-400 cursor-pointer md:h-[360px] md:aspect-auto">
					<PreloadImage
						className="w-full h-full object-cover rounded-lg"
						onClick={() => navigate(post.Thread.view_url)}
						src={isMobile() ? post.Thread.mobile_thumbnail_url : post.Thread.thumbnail_url}
						alt={post.title ? post.title : post.Thread.title}
					/>
				</div>

				<h2 className="text-md font-semibold mt-2 mb-2 cursor-pointer hover:text-primary md:mt-4 md:mb-4 md:text-xl">
					<span onClick={() => navigate(post.Thread.view_url)}>{post.title ? post.title : post.Thread.title}</span>
				</h2>

				<p className="text-xs font-light text-gray-500 md:text-sm md:font-bold md:text">
					<RelativeTime timestamp={post.Thread.post_date} />
				</p>

				<p className="text-sm mt-2 line-clamp-2 lg:hidden">{post.excerpt}</p>
			</div>
		);
	} else {
		return (
			<div className="first-post mb-4">
				<div className="w-full aspect-[3/1.5] md:h-[360px] md:aspect-auto">
					<PlaceholderLoading shape="rect" width={'100%'} height={'100%'} />
				</div>
				<br/>

				<PlaceholderLoading shape="rect" width={'100%'} height={84} />
				<br/>

				<PlaceholderLoading shape="rect" width={'100%'} height={20} />
				<br/>

				<div className="lg:hidden">
					<PlaceholderLoading shape="rect" width={'100%'} height={40} />
				</div>
			</div>
		);
	}
}

const SecondPost = ({post = null}) => {
	const navigate = useNavigate();

	if (post) {
		const Thumbnail = () => {
			return <LazyLoad className="w-full h-auto rounded-lg bg-gray-400 cursor-pointer md:h-[360px]">
				<img className="w-full h-full rounded-lg object-cover"
				     onClick={() => navigate(post.Thread.view_url)}
				     src={isMobile() ? post.Thread.mobile_thumbnail_url : post.Thread.thumbnail_url}
				     alt={post.title ? post.title : post.Thread.title}/>
			</LazyLoad>;
		}

		return (
			<div className="second-post mt-2 mb-4 lg:mt-0">
				<div className="hidden lg:block">
					<Thumbnail />

					<h2 className="text-md font-semibold mt-2 mb-2 cursor-pointer hover:text-primary">
						<span onClick={() => navigate(post.Thread.view_url)}>{post.title ? post.title : post.Thread.title}</span>
					</h2>

					<p className="text-sm font-light text-gray-500 lg:font-bold">
						<RelativeTime timestamp={post.Thread.post_date} />
					</p>
				</div>

				<div className="block lg:hidden">
					<MainstreamPost title={post.title ? post.title : post.Thread.title}
					                category={post.Thread.Forum.title}
					                date={<RelativeTime timestamp={post.Thread.post_date} />}
					                thumbnailUrl={isMobile() ? post.Thread.mobile_thumbnail_url : post.Thread.thumbnail_url}
					                threadViewUrl={post.Thread.view_url}
					                categoryViewUrl={post.Thread.Forum.view_url}
					                excerpt={post.excerpt}/>
				</div>
			</div>
		);
	} else {
		return (
			<div className="second-post mt-2 mb-4 lg:mt-0">
				<div className="hidden lg:block">
					<div className="w-full aspect-[2/3] md:h-[360px]">
						<PlaceholderLoading shape="rect" width={'100%'} height={'100%'}/>
					</div>
					<br/>

					<PlaceholderLoading shape="rect" width={'100%'} height={84} />
					<br/>

					<PlaceholderLoading shape="rect" width={'100%'} height={20} />
					<br/>
				</div>

				<div className="block lg:hidden">
					<PlaceholderLoading shape="rect" width={'100%'} height={140} />
				</div>
			</div>
		);
	}
}

const ThirdPost = ({post = null}) => {
	const navigate = useNavigate();

	if (post) {
		return (
			<div className="">
				<div className="hidden lg:block">
					<LazyLoad
						className="w-full h-auto aspect-square overflow-hidden rounded-lg bg-gray-400 cursor-pointer">
						<img src={isMobile() ? post.Thread.mobile_thumbnail_url : post.Thread.thumbnail_url}
						     onClick={() => navigate(post.Thread.view_url)}
						     alt={post.title ? post.title : post.Thread.title}
						     className="w-full h-full object-cover rounded-lg"
						/>
					</LazyLoad>

					<h4 className="font-semibold mt-2 mb-2 line-clamp-2 cursor-pointer hover:text-primary">
						<span onClick={() => navigate(post.Thread.view_url)}
						      title={post.title ? post.title : post.Thread.title}>
							{post.title ? post.title : post.Thread.title}
						</span>
					</h4>

					<div className="text-sm">
						<span className="text-primary font-semibold cursor-pointer"
						      onClick={() => navigate(post.Thread.Forum.view_url)}>{post.Thread.Forum.title}</span>
						<span className="text-gray-500"> • <RelativeTime timestamp={post.Thread.post_date}/></span>
					</div>
				</div>

				<div className="block lg:hidden">
					<MainstreamPost title={post.title ? post.title : post.Thread.title}
					                category={post.Thread.Forum.title}
					                date={<RelativeTime timestamp={post.Thread.post_date}/>}
					                thumbnailUrl={isMobile() ? post.Thread.mobile_thumbnail_url : post.Thread.thumbnail_url}
					                threadViewUrl={post.Thread.view_url}
					                categoryViewUrl={post.Thread.Forum.view_url}
					                excerpt={post.excerpt}/>
				</div>
			</div>
		);
	} else {
		return (
			<div className="">
				<div className="hidden lg:block">
					<div className="w-full aspect-square">
						<PlaceholderLoading shape="rect" width={'100%'} height={'100%'}/>
					</div>

					<PlaceholderLoading shape="rect" width={'100%'} height={84}/>
					<br/>

					<PlaceholderLoading shape="rect" width={'100%'} height={20}/>
					<br/>
				</div>

				<div className="block lg:hidden">
					<PlaceholderLoading shape="rect" width={'100%'} height={140}/>
				</div>
			</div>
		);
	}
}

const FeaturedPostsSection = () => {
	const {featuredPosts} = useContext(HomeContext);

	return (
		<div>
			<div className="flex flex-col lg:flex-row">
				<div className="flex-grow lg:w-2/3 lg:pr-2 lg:pl-0">
					<FirstPost post={featuredPosts ? featuredPosts[0] : null}/>
				</div>

				<hr className="mt-1 mb-1 bg-primary w-full h-[1px] border-0 rounded opacity-20 lg:hidden"/>

				<div className="lg:w-1/3 lg:pl-2">
					<SecondPost post={featuredPosts ? featuredPosts[1] : null}/>
				</div>
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 lg:mt-0">
				<ThirdPost post={featuredPosts ? featuredPosts[2] : null}/>
				<ThirdPost post={featuredPosts ? featuredPosts[3] : null}/>
				<ThirdPost post={featuredPosts ? featuredPosts[4] : null}/>
			</div>
		</div>
	)
};

export default FeaturedPostsSection;