import React from 'react';
import { useLocation } from 'react-router-dom';

import NotFound from './NotFound';

import Thread from "./Thread/Thread";
import Forum from "./Forum/Forum";
import ForumPostThread from "./ForumPostThread/ForumPostThread";

const DynamicRouteHandler = () => {
	const location = useLocation();

	let urlPattern, match;

	// Thread route matches
	urlPattern = /^\/([\w-]+)\.t(\d+)\/?$/;
	match = location.pathname.match(urlPattern);
	if (match) {
		const [_, slug, threadId] = match;

		if (threadId) {
			return <Thread threadId={threadId} />;
		}
	}

	// Forum route matches
	urlPattern = /^\/([\w-]+)\.f(\d+)\/?$/;
	match = location.pathname.match(urlPattern);

	if (match) {
		const [_, slug, forumId] = match;

		if (forumId) {
			const searchParams = new URLSearchParams(location.search);
			const hasPostThreadParam = searchParams.get('post_thread');
			if (hasPostThreadParam) {
				return <ForumPostThread />
			} else {
				return <Forum />;
			}
		}
	}

	// If no match or invalid ID, render the NotFound component
	return <NotFound />;
};

export default DynamicRouteHandler;
