import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const NodeMessageCount = ({title, count, isMobile = false}) => {
	return (
		<>
			<span className="text-xs text-url opacity-85">{title}</span>
			<span className={`font-semibold ${isMobile ? 'text-xs' : 'text-md'}`}>{count}</span>
		</>
	)
}

const Node = ({node}) => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();

	if (node && node.type_data.can_create_thread) {
		return (
			<div className="group node border-b border-borderColor hover:cursor-pointer hover:bg-gray-200"
			     onClick={() => navigate(node.view_url + '?post_thread=true')}
			>
				<div className="flex items-center py-4 px-2">
					<div className="flex-1 min-w-0">
						<h3 className={`text-url text-xl ${(node.type_data && node.type_data.is_unread) ? 'font-bold' : 'font-light'} group-hover:underline`}>
							{node.title}
						</h3>

						<div className="md:hidden">
							<div className="flex text-xs text-url opacity-85 items-center">
								{(node.type_data && node.type_data.discussion_count) && (
									<span className="inline-flex gap-x-2 mr-2">
										<NodeMessageCount title={t('threads')} count={node.type_data.discussion_count}/>
									</span>
								)}

								{(node.type_data && node.type_data.message_count) && (
									<span className="inline-flex gap-x-2">
										<NodeMessageCount title={t('posts')} count={node.type_data.message_count}/>
									</span>
								)}
							</div>
						</div>
					</div>

					<div className="w-[70px] hidden flex-col justify-center items-center md:flex">
						{(node.type_data && node.type_data.discussion_count) && (
							<NodeMessageCount title={'Chủ đề'} count={node.type_data.discussion_count}/>)}
					</div>

					<div className="w-[70px] hidden flex-col justify-center items-center md:flex">
						{(node.type_data && node.type_data.message_count) && (
							<NodeMessageCount title={'Bài viết'} count={node.type_data.message_count}/>)}
					</div>
				</div>
			</div>
		)
			;
	}
}

export default Node;