import React, {useContext} from 'react';
import {MemberContext} from "../../../context/memberContext";
import MemberAvatar from "../../common/MemberAvatar";
import SideBlock from "../../common/SideBlock";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const SidenavSection = () => {
	const { i18n, t } = useTranslation();

	const navigate = useNavigate();

	const {
		followers, followersLoading, hasMoreFollowers, loadMoreFollowers,
	} = useContext(MemberContext);

	return (
		<div className="hidden lg:block">
			{(followers && followers.length > 0) && (
				<SideBlock title={t('followers')}>
					<ul>
						{followers.map((follower, index) => (
							<li key={index} className="flex items-center mb-4">
								<div className="w-8 h-8 mr-2">
									<MemberAvatar imgUrl={follower.avatar_urls.s}/>
								</div>

								<h4 className="font-bold">
									<button onClick={() => navigate(follower.view_url)}>{follower.username}</button>
								</h4>
							</li>
						))}
					</ul>

					{hasMoreFollowers && (
						<div className="text-center mt-4">
							<button onClick={loadMoreFollowers}
							        className="w-full px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
								{followersLoading ? 'Loading...' : 'Load More'}
							</button>
						</div>
					)}
				</SideBlock>
			)}
		</div>
	);
}

export default SidenavSection;