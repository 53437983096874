import React, {useContext, useEffect, useRef} from "react";
import EditorJS from "@editorjs/editorjs";
import { AuthContext } from "../../context/authContext";
import { EDITOR_JS_TOOLS } from "./Tools";
import {Internationalization} from "./extensions/Internationalization";

const EditorComponent = ({ initialMessage = null, attachmentKey = null, onMessageChange = null}) => {
	const ejInstance = useRef(false);

	const { visitor } = useContext(AuthContext);

	useEffect(() => {
		if (visitor && attachmentKey && !ejInstance.current) {
			const editor = new EditorJS({
				holder: 'editorjs',
				onReady: () => {
					ejInstance.current = editor;
					if (initialMessage) {
						editor.blocks.insert(
							'paragraph',
							{
								'text': '...'
							}
						);

						editor.blocks.renderFromHTML(initialMessage);
					}
				},
				autofocus: true,
				onChange: async () => {
					let content = await editor.saver.save();

					if (onMessageChange) {
						onMessageChange(content);
					}
				},
				tools: EDITOR_JS_TOOLS({visitor, attachmentKey}),
				i18n: Internationalization()
			});

			ejInstance.current = true;
		}
	}, [attachmentKey, initialMessage, onMessageChange, visitor]);

	return <div id="editorjs" className="editor-container px-4 py-2 shadow-xl rounded-xl md:px-8 md:py-4 md:shadow-2xl md:rounded-2xl"></div>;
}

export default EditorComponent;
