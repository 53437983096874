export const Internationalization = () => {
	/**
	 * Internationalzation config
	 */

	return {
		messages: {
			/**
			 * Other below: translation of different UI components of the editor.js core
			 */
			ui: {
				"blockTunes": {
					"toggler": {
						"Click to tune": "Click để điều chỉnh",
						"or drag to move": "hoặc kéo để di chuyển"
					},
				},
				"inlineToolbar": {
					"converter": {
						"Convert to": "Chuyển sang"
					}
				},
				"toolbar": {
					"toolbox": {
						"Add": "Thêm"
					}
				}
			},

			/**
			 * Section for translation Tool Names: both block and inline tools
			 */
			toolNames: {
				"Text": "Văn bản",
				"Heading": "Tiêu đề",
				"List": "Danh sách",
				"Warning": "Cảnh báo",
				"Checklist": "Checklist",
				"Quote": "Trích dẫn",
				"Code": "Code",
				"Delimiter": "Phân cách",
				"Raw HTML": "Mã HTML",
				"Table": "Bảng",
				"Link": "Liên kết",
				"Marker": "Đánh dấu",
				"Bold": "In đậm",
				"Italic": "In nghiêng",
				"InlineCode": "Inline Code",
				"Image": "Ảnh",
				"Attachment": "Đính kèm",
				"Break Line": "Xuống dòng"
			},

			/**
			 * Section for passing translations to the external tools classes
			 */
			tools: {
				/**
				 * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
				 * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
				 */
				"paragraph": {
					"Start typing your text...": "Nhập văn bản..."
				},

				"header": {
					"Enter a header": "Nhập tiêu đề"
				},

				"quote": {
					"Enter a quote": "Nhập trích dẫn"
				},

				"warning": {
					"Title": "Tiêu đề",
					"Message": "Văn bản",
				},

				/**
				 * Link is the internal Inline Tool
				 */
				"link": {
					"Add a link": "Thêm liên kết"
				},
				/**
				 * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
				 */
				"stub": {
					'The block can not be displayed correctly.': 'Không thể hiển thị block'
				}
			},

			/**
			 * Section allows to translate Block Tunes
			 */
			blockTunes: {
				/**
				 * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
				 * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
				 *
				 * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
				 */
				"delete": {
					"Delete": "Xoá"
				},
				"moveUp": {
					"Move up": "Đưa lên trên"
				},
				"moveDown": {
					"Move down": "Đưa xuống dưới"
				}
			},
		}
	};
}