import {CategoryContext, CategoryProvider} from "../../context/categoryContext";
import Body from "../common/Body";
import React, {useContext} from "react";
import {generateHeadTags} from "react-seo-tools";
import {Helmet} from "react-helmet";
import StickySection from "./section/StickySection";
import ForumSection from "./section/ForumSection";
import LoadingContent from "../common/LoadingContent";
import LoadingNoMoreContent from "../common/LoadingNoMoreContent";

const MainContent = () => {
	const {category, isLoading, forums, stickyThreads} = useContext(CategoryContext);

	if (category && forums) {
		if (!isLoading) {
			return (
				<div>
					<Helmet>
						{generateHeadTags({
							title: category.title,
							description: category.description,
							openGraph: {
								type: 'website',
								title: category.title
							},
						})}
					</Helmet>

					<section className="mb-4 md:mb-8">
						<StickySection category={category} stickyThreads={stickyThreads} />
					</section>

					{forums.map((forumData, index) => (
						<section key={index} className="mb-4 md:mb-8">
							<ForumSection forumData={forumData} />
						</section>
					))}
				</div>
			);
		} else {
			return <LoadingContent />
		}
	} else {
		return <LoadingNoMoreContent />
	}
}

const Category = () => {
	return <CategoryProvider>
		<Body mainContent={<MainContent />} sidebarContent={'default'} />
	</CategoryProvider>
}

export default Category;