import axios from "axios";
import {API_BASE_URL, API_KEY} from "../config/config";
import {useCallback, useEffect} from "react";

export default function useDebounce(effect, dependencies, delay) {
	const callback = useCallback(effect, [effect]);

	useEffect(() => {
		const timeout = setTimeout(callback, delay);
		return () => clearTimeout(timeout);
	}, [callback, delay]);
}

export const getCurrentBaseUrl = () => {
	const getUrl = window.location;
	let baseUrl = getUrl.protocol + "//" + getUrl.host;
	baseUrl = baseUrl.replace(/\/$/, '');

	return baseUrl;
}

export const copyToClipboard = (text) => {
	navigator.clipboard.writeText(text);
}

export const openPopupWindow = (url) => {
	window.open(url, 'fbShareWindow', 'height=450, width=550, top=' + (window.outerHeight / 2 - 275) + ', left=' + (window.outerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
}

export const isMobile = () => {
	// Check viewport width
	const isMobileViewport = window.innerWidth <= 480;

	// Check user agent
	const userAgent = navigator.userAgent || window.opera;
	const isMobileUserAgent = /android|iPad|iPhone|iPod/.test(userAgent.toLowerCase());

	return isMobileViewport || isMobileUserAgent;
};

export const checkImageExists = (imageUrl) => {
	if (imageUrl.match(/\.(jpeg|jpg|gif|png)$/) != null) {
		const http = new XMLHttpRequest();

		http.open('HEAD', imageUrl, false);
		http.send();

		return http.status !== 404;
	} else {
		return false;
	}
}

export const generateAttachmentKey = async (visitor, type, contextKey, contextValue) => {
	const data = new URLSearchParams();
	data.append('type', type);
	data.append(`context[${contextKey}]`, contextValue);

	const response = await axios.post(`${API_BASE_URL}/attachments/new-key`, data, {
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		withCredentials: true
	});

	return response.data.key;
};