import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import React from "react";

const PopupMenu = ({label, children}) => {
	return (
		<Menu menuButton={<MenuButton>{label}</MenuButton>} transition className="shadow-lg">
			{children}
		</Menu>
	)
}

export default PopupMenu;