import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const SearchBar = () => {
	const { i18n, t } = useTranslation();

	const [searchQuery, setSearchQuery] = useState('');
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();

		if (searchQuery.trim() !== '') {
			navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
		} else {
			navigate('/search');
		}
	};

	return (
		<form onSubmit={handleSubmit} className="flex w-full bg-gray-100 px-6 py-3 rounded outline outline-transparent focus-within:outline-primary focus-within:bg-transparent">
			<input type='text'
			       placeholder={t('search_something')}
			       value={searchQuery}
			       onChange={(e) => setSearchQuery(e.target.value)}
			       className='w-full text-sm bg-transparent rounded outline-none pr-2'/>
			<FontAwesomeIcon icon={faSearch} className="mt-0.5 text-gray-500"/>
		</form>
	);
}

export default SearchBar;