import React, {useContext} from 'react';
import {SearchContext} from "../../../context/searchContext";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingContent from "../../common/LoadingContent";
import LoadingNoMoreContent from "../../common/LoadingNoMoreContent";
import MainstreamPost from "../../common/MainstreamPost";
import Highlighter from "react-highlight-words";
import RelativeTime from "../../common/RelativeTime";
import {isMobile} from "../../../utils/utils";

const ResultSection = () => {
	const {
		searchQuery, threads, threadsLoading, hasMoreThreads, loadMoreThreads,
	} = useContext(SearchContext);

	if (searchQuery && threads) {
		if (!threadsLoading) {
			return (
				<div>
					<InfiniteScroll
						next={loadMoreThreads}
						hasMore={hasMoreThreads}
						loader={<LoadingContent/>}
						dataLength={threads.length}
						endMessage={<LoadingNoMoreContent/>}
					>
						{threads.map((thread, index) =>
							<div key={index}>
								<MainstreamPost
									title={<Highlighter
										highlightClassName="highligh-text bg-transparent text-primary font-bold"
										searchWords={[searchQuery]}
										autoEscape={true}
										textToHighlight={thread.title}
									/>}
									category={thread.Forum.title}
									author={thread.username}
									date={<RelativeTime timestamp={thread.post_date} />}
									thumbnailUrl={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
									threadViewUrl={thread.view_url}
									authorViewUrl={thread.User ? thread.User.view_url : null}
									categoryViewUrl={thread.Forum.view_url}
									excerpt={<Highlighter
										highlightClassName="highligh-text bg-transparent font-bold"
										searchWords={[searchQuery]}
										autoEscape={true}
										textToHighlight={thread.excerpt}
									/>}
								/>
							</div>
						)}
					</InfiniteScroll>
				</div>
			);
		} else {
			return <LoadingContent />
		}
	} else {
		return <LoadingNoMoreContent />
	}
}

export default ResultSection;