export const getFacebookVideoId = (url) => {
	// Match both /watch/?v= and /reel/ URLs
	const regex = /(?:\/watch\/\?v=|\/reel\/|\/videos\/)(\d+)/;
	const match = url.match(regex);
	return match ? match[1] : null;
}

export const getYoutubeVideoId = (url) => {
	const regex = [
		/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i,
		/(?:youtube\.com\/.*[?&]v=)([^"&?\/\s]{11})/i,
		/(?:youtu\.be\/)([^"&?\/\s]{11})/i
	];

	for (let exp of regex) {
		const match = url.match(exp);
		if (match) {
			return match[1];
		}
	}

	return null;
}