import {faCheckCircle, faCircle, faCopy, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isValidatedUser} from "../../../utils/user";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons";
import {copyToClipboard, openPopupWindow} from "../../../utils/utils";
import {useTranslation} from "react-i18next";
import PopupMenu from "../../common/PopupMenu";
import {MenuItem} from "@szhsin/react-menu";
import RelativeTime from "../../common/RelativeTime";
import React from "react";
import {useNavigate} from "react-router-dom";

const ThreadModeratorButton = ({thread, firstPost}) => {
	const {i18n, t} = useTranslation();
	const navigate = useNavigate();

	const ThreadModeratorLinks = [
		{
			title: t('edit_thread'),
			path: `${thread.view_url}edit`,
			display: thread.can_edit
		},

		{
			title: t('edit_post_content'),
			path: `${firstPost.view_url}?edit=true`,
			display: firstPost.can_edit,
			isUseNavigate: true
		},

		{
			title: t('edit_thumbnail'),
			path: `${thread.view_url}edit-thumbnail`,
			display: thread.can_edit_thumbnail
		},

		{
			title: 'Xoá bài viết',
			path: `${thread.view_url}delete`,
			display: thread.can_soft_delete && !thread.is_deleted
		},
		{
			title: 'Khôi phục bài viết đã xoá',
			path: `${thread.view_url}undelete`,
			display: thread.can_undelete && thread.is_deleted
		},

		{
			title: thread.sticky ? 'Bỏ dán bài viết' : 'Dán bài viết',
			path: `${thread.view_url}stick-unstick`,
			display: thread.can_stick_unstick
		},

		{
			title: 'Duyệt bài viết',
			path: `${thread.view_url}approve`,
			display: thread.can_approve_unapprove && thread.discussion_state === 'moderated'
		},

		{
			title: 'Di chuyển bài viết',
			path: `${thread.view_url}move`,
			display: thread.can_move
		},

		{
			title: t('manage_tags'),
			path: `${thread.view_url}tags`,
			display: thread.can_manage_tags
		}
	];

	return (
		<PopupMenu label={
			<button className="flex items-center justify-center px-2 py-1 border rounded text-sm text-gray-500 transition max-md:flex-1 hover:border-gray-500 hover:bg-gray-500 hover:text-white">
				<FontAwesomeIcon icon={faEllipsisH} className="mr-1"/>
				<span className="hidden md:inline-block">{t('options')}</span>
			</button>
		}>
			{ThreadModeratorLinks.map((link, index) => {
				if (link.display) {
					if (link.isUseNavigate) {
						return <MenuItem onClick={() => navigate(link.path)}>{link.title}</MenuItem>;
					} else {
						return <MenuItem href={link.path}>{link.title}</MenuItem>;
					}
				}
			})}
		</PopupMenu>
	);
}

const HeaderSection = ({thread, firstPost}) => {
	const {i18n, t} = useTranslation();

	const navigate = useNavigate();

	const handleCopyButton = () => copyToClipboard(window.location.href);
	const handleShareButton = () => openPopupWindow(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);

	return (
		<div>
			<h1 className="text-2xl font-semibold mb-4 md:text-3xl md:font-bold">{thread.title}</h1>

			<div className="flex flex-col items-start justify-center md:flex-row md:items-center md:justify-between">
				<div className="flex items-center mb-4 md:mb-0">
					<img src={thread.User && thread.User.avatar_urls.s}
					     alt={thread.username}
					     onClick={() => navigate(thread.User ? thread.User.view_url : '#')}
					     className="w-12 h-12 rounded-full mr-2 cursor-pointer"
					/>

					<div className="text-md">
						<span onClick={() => navigate(thread.User ? thread.User.view_url : '#')} className="flex items-center cursor-pointer">
							<span className="text-red-500 font-semibold">{thread.username}</span>

							{(thread.User && isValidatedUser(Object.values(thread.User.secondary_group_ids))) && (
								<FontAwesomeIcon icon={faCheckCircle} className="text-blue-700 ml-1" />
							)}
						</span>

						<div className="text-gray-500 text-sm flex flex-row items-center">
							<span><RelativeTime timestamp={thread.post_date} /></span>

							{thread.User && (
								<span className="hidden ml-1 items-center md:flex">
									<FontAwesomeIcon icon={faCircle} className="text-[4px] mr-1 md:text-[0.5em]"/>
									<span>{t('posts')}: {thread.User.message_count}</span>
								</span>
							)}

							<span className="ml-1 flex items-center">
								<FontAwesomeIcon icon={faCircle} className="text-[4px] mr-1 md:text-[0.5em]"/>
								<span>{t('views')}: {thread.view_count}</span>
							</span>
						</div>
					</div>
				</div>

				<div
					className="flex w-full items-center justify-center space-x-2 ml-auto mr-auto md:w-auto md:ml-0 md:mr-0 md:justify-start">
					{thread.can_edit && (<ThreadModeratorButton thread={thread} firstPost={firstPost} />)}

					<button onClick={handleCopyButton}
					        className="flex items-center justify-center px-2 py-1 border rounded text-sm text-gray-500 transition max-md:flex-1 hover:border-gray-500 hover:bg-gray-500 hover:text-white">
						<FontAwesomeIcon icon={faCopy} className="mr-1" />
						{t('copy_url')}
					</button>

					<button onClick={handleShareButton}
					        className="flex items-center justify-center px-2 py-1 bg-blue-600 border text-sm text-white rounded transition max-md:flex-1 hover:bg-blue-900">
						<FontAwesomeIcon icon={faFacebookF} className="mr-1" />
						{t('share')}
					</button>
				</div>
			</div>
		</div>
	);
}

export default HeaderSection;