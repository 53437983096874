import React, { createContext, useContext, useState } from 'react';
import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL, API_KEY } from "../config/config";
import { AuthContext } from "./authContext";
import {generateAttachmentKey} from "../utils/utils";

export const ForumContext = createContext(null);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 10, // 10 minutes
			refetchOnWindowFocus: false, // Avoid refetching on window focus
		},
	},
});

const fetchForum = async ({ queryKey }) => {
	const [_, { forumId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/forums/${forumId}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};

	const response = await axios.request(config);
	return response.data.forum;
};

const fetchForumPostThreadAttachmentKey = async ({ queryKey }) => {
	const [_, { forumId, visitor }] = queryKey;

	return generateAttachmentKey(visitor, 'post', 'node_id', forumId);
}

const fetchThreads = async ({ pageParam = 1, queryKey }) => {
	const [_, { forumId, visitor }] = queryKey;

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/forums/${forumId}/threads?page=${pageParam}`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true,
	};

	const response = await axios.request(config);
	return {
		threads: Object.values(response.data.threads || {}),
		stickyThreads: Object.values(response.data.sticky || {}),
		pagination: response.data.pagination,
	};
};

/**
 * @param {Number} forumId
 * @param {string} title
 * @param {string} message
 * @param {Object} seoData
 * @param {$ObjMap} customFields
 * @param {string} attachmentKey
 * @param {Object} visitor
 */
const postThread = async ({ forumId, title, message, seoData, customFieldsData, attachmentKey, visitor }) => {
	const data = new URLSearchParams();
	data.append('node_id', forumId);
	data.append('title', title);
	data.append('message', message);

	if (attachmentKey) {
		data.append('attachment_key', attachmentKey);
	}

	if (seoData) {
		if (seoData.seoTitle) {
			data.append('meta_title', seoData.seoTitle);
		}

		if (seoData.seoDescription) {
			data.append('meta_description', seoData.seoDescription);
		}

		if (seoData.seoImageUrl) {
			data.append('meta_ogimage', seoData.seoImageUrl);
		}
	}

	if (customFieldsData) {
		for (let [key, value] of customFieldsData) {
			data.append(`custom_fields[${key}]`, value);
		}
	}

	const response = await axios.post(`${API_BASE_URL}/threads/`, data, {
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		withCredentials: true
	});

	return response.data;
};

export const ForumProvider = ({ children }) => {
	const { visitor } = useContext(AuthContext);
	const location = useLocation();

	let forumId = null;
	const urlPattern = /^\/([\w-]+)\.f(\d+)\/?$/;
	const match = location.pathname.match(urlPattern);
	if (match) {
		forumId = match[2];
	}

	const { data: forum, isLoading: forumLoading, error: forumError } = useQuery({
		queryKey: ['forum', { forumId, visitor }],
		queryFn: fetchForum,
		enabled: !!visitor && !!forumId,
	});

	const {
		data: threadsData,
		fetchNextPage: loadMoreThreads,
		hasNextPage: hasMoreThreads,
	} = useInfiniteQuery({
		queryKey: ['threads', { forumId, visitor }],
		queryFn: fetchThreads,
		getNextPageParam: (lastPage) => lastPage.pagination.current_page < lastPage.pagination.last_page
			? lastPage.pagination.current_page + 1
			: undefined,
		enabled: !!visitor && !!forumId,
	});

	const threads = threadsData?.pages.flatMap(page => page.threads) || [];
	const stickyThreads = threadsData?.pages.flatMap(page => page.stickyThreads) || [];

	const { data: attachmentKey, isLoading: attachmentKeyLoading, error: attachmentKeyError } = useQuery({
		queryKey: ['attachmentKey', {forumId, visitor}],
		queryFn: fetchForumPostThreadAttachmentKey,
		enabled: !!visitor && !!forumId,
	});

	const postThreadMutation = useMutation({
		mutationFn: async ({ forumId, title, message, seoData, customFieldsData, attachmentKey }) => {
			return postThread({ forumId, title, message, seoData, customFieldsData, attachmentKey, visitor });
		},
		onError: (error) => {
			console.error('Error posting thread:', error);
		},
		onSuccess: (data) => {
			console.log('Thread posted successfully:', data);
			queryClient.invalidateQueries(['threads', { forumId, visitor }]);
		}
	});

	return (
		<ForumContext.Provider value={{
			forum, forumLoading, forumError,
			stickyThreads,
			threads, hasMoreThreads, loadMoreThreads,
			postThreadMutation,
			attachmentKey, attachmentKeyLoading
		}}>
			{children}
		</ForumContext.Provider>
	);
};

export const ForumContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<ForumProvider>{children}</ForumProvider>
	</QueryClientProvider>
);