import React from 'react';

import LazyLoad from 'react-lazyload';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faStar} from "@fortawesome/free-solid-svg-icons";
import RelativeTime from "../../common/RelativeTime";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";
import PreloadImage from "../../common/PreloadImage";
import PlaceholderLoading from "react-placeholder-loading";

const FirstPost = ({thread}) => {
	const navigate = useNavigate();

	if (thread) {
		return (
			<div className="first-post mb-4">
				<PreloadImage
					className="w-full h-auto aspect-[3/1.5] rounded-lg cursor-pointer md:h-[420px] md:aspect-auto"
					onClick={() => navigate(thread.view_url)}
					src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
					alt={thread.title}
				/>

				<h2 className="text-md font-semibold mt-2 mb-2 cursor-pointer md:mt-4 md:mb-4 md:text-xl hover:text-primary">
					<span onClick={() => navigate(thread.view_url)}>{thread.title}</span>
				</h2>

				<p className="text-xs font-light text-gray-500 md:font-bold md:text-sm">
					<RelativeTime timestamp={thread.post_date} />
				</p>

				<p className="text-sm mt-2 line-clamp-2 lg:hidden">{thread.excerpt}</p>
			</div>
		);
	} else {
		return (
			<div className="first-post mb-4">
				<div className="w-full h-auto aspect-[3/1.5] md:h-[420px] md:aspect-auto">
					<PlaceholderLoading shape="rect" width={'100%'} height={'100%'}/>
				</div>

				<PlaceholderLoading shape="rect" width={'100%'} height={84}/>
				<br/>

				<PlaceholderLoading shape="rect" width={'100%'} height={20}/>
				<br/>

				<div className="lg:hidden">
					<PlaceholderLoading shape="rect" width={'100%'} height={40}/>
				</div>
			</div>
		);
	}
}

const SecondPost = ({thread}) => {
	const navigate = useNavigate();

	if (thread) {
		const Thumbnail = () => {
			return <LazyLoad className="w-full h-auto rounded-lg bg-gray-400 cursor-pointer md:h-[160px]">
				<img className="w-full h-full object-cover rounded-lg"
				     onClick={() => navigate(thread.view_url)}
				     src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
				     alt={thread.title}
				/>
			</LazyLoad>;
		}

		return (
			<div className="second-post mb-4">
				<div className="hidden lg:block">
					<Thumbnail/>
				</div>

				<h2 className="text-md font-semibold mt-2 mb-2 cursor-pointer hover:text-primary">
					<span onClick={() => navigate(thread.view_url)}>{thread.title}</span>
				</h2>

				<p className="text-sm font-light text-gray-500 lg:font-bold">
					<RelativeTime timestamp={thread.post_date} />
				</p>
			</div>
		);
	} else {
		return (
			<div className="second-post mb-4">
				<div className="w-full aspect-[2/3] md:h-[360px]">
					<PlaceholderLoading shape="rect" width={'100%'} height={'100%'}/>
				</div>
				<br/>

				<PlaceholderLoading shape="rect" width={'100%'} height={84}/>
				<br/>

				<PlaceholderLoading shape="rect" width={'100%'} height={20}/>
				<br/>
			</div>
		);
	}
}

const ThirdPost = ({thread}) => {
	const navigate = useNavigate();

	if (thread) {
		return (
			<li className="items-start mb-4 lg:flex lg:flex-row">
				<FontAwesomeIcon icon={faCircle} className="hidden mt-1 mr-2 text-primary lg:block"/>

				<span onClick={() => navigate(thread.view_url)}
				   className="text-md inline-block font-semibold text-md cursor-pointer md:tracking-wide md:text-xl hover:text-primary"
				   title={thread.title}>
					{thread.title}
				</span>

				<p className="mt-2 text-sm font-light text-gray-500 lg:hidden lg:font-bold">
					<RelativeTime timestamp={thread.post_date} />
				</p>
			</li>
		);
	}
}

const StickySection = ({category, stickyThreads}) => {
	if (category && stickyThreads) {
		return (
			<div>
				<div className="flex flex-col">
					<div className="flex items-center mb-2">
						<FontAwesomeIcon icon={faStar} className="text-primary"/>
						<span className="text-gray-500 text-lg font-bold ml-2">{category.title}</span>
					</div>

					<div className="flex flex-col lg:flex-row">
						<div className="flex-grow lg:w-2/3 lg:pr-2">
							<FirstPost thread={stickyThreads[0]}/>
						</div>

						<hr className="mt-4 mb-4 bg-primary w-full h-1 border-0 rounded lg:hidden" />

						<div className="lg:w-1/3 lg:pl-2">
							{stickyThreads[1] && (<SecondPost thread={stickyThreads[1]}/>)}
							{stickyThreads[2] && (<SecondPost thread={stickyThreads[2]}/>)}
						</div>
					</div>
				</div>

				<hr className="hidden mt-4 mb-4 bg-primary w-full h-1 border-0 rounded lg:block"/>

				<ul>
					{stickyThreads[3] && (<ThirdPost thread={stickyThreads[3]}/>)}
					{stickyThreads[4] && <ThirdPost thread={stickyThreads[4]}/>}
				</ul>
			</div>
		)
	}
};

export default StickySection;