import React from "react";
import SectionHeader from "../../common/SectionHeader";
import MainstreamPost from "../../common/MainstreamPost";
import {useTranslation} from "react-i18next";
import RelativeTime from "../../common/RelativeTime";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const ForumSection = ({forumData}) => {
	const { i18n, t } = useTranslation();

	const navigate = useNavigate();

	if (forumData) {
		let threads = forumData.threads;
		if (typeof threads === "object") {
			threads = Object.values(threads);
		}

		return (
			<div>
				<div className="">
					<SectionHeader title={forumData.forum.title} url={forumData.forum.view_url}/>
				</div>

				{threads.map((thread, index) => {
					return (
						<MainstreamPost
							key={index}
							title={thread.title}
							author={thread.username}
							date={<RelativeTime timestamp={thread.post_date} />}
							thumbnailUrl={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
							threadViewUrl={thread.view_url}
							authorViewUrl={thread.User ? thread.User.view_url : null}
							categoryViewUrl={thread.Forum.view_url}
							excerpt={thread.excerpt}
						/>
					);
				})}

				<div className="text-center mt-4">
					<button onClick={() => navigate(forumData.forum.view_url)}
					   className="w-full px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
						{t('load_more')}
					</button>
				</div>
			</div>
		);
	}
}

export default ForumSection;