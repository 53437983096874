import React from "react";

const FloatingLabelInput = ({inputId, title, defaultValue, onChange}) => {
	return (
		<div className="floating-input-label relative z-0 w-full mb-5">
			<input
				type="text"
				id={inputId}
				defaultValue={defaultValue}
				placeholder=" "
				onChange={onChange}
				className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-red-500 focus:border-opacity-50"
				autoComplete="off"
			/>
			<label htmlFor={inputId}
			       className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{title}</label>
		</div>
	);
}

export default FloatingLabelInput;