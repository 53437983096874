import React, {useContext} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HomeContext} from "../../../context/homeContext";
import LazyLoad from "react-lazyload";
import {useTranslation} from "react-i18next";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import SectionHeader from "../../common/SectionHeader";
import RelativeTime from "../../common/RelativeTime";
import {useNavigate} from "react-router-dom";
import {isMobile} from "../../../utils/utils";

const ReviewItem = ({thread}) => {
	const navigate = useNavigate();

	return (
		<div>
			<LazyLoad className="w-full h-auto aspect-square overflow-hidden rounded-lg bg-gray-400 cursor-pointer">
				<img src={isMobile() ? thread.mobile_thumbnail_url : thread.thumbnail_url}
				     onClick={() => navigate(thread.view_url)}
				     alt={thread.title}
				     className="w-full h-full object-cover rounded-lg"
				/>
			</LazyLoad>

			<h4 onClick={() => navigate(thread.view_url)}
			    className="font-semibold mt-2 mb-2 line-clamp-2 cursor-pointer hover:text-primary">
				{thread.title}
			</h4>

			<div className="text-sm">
				<span onClick={() => navigate(thread.Forum.view_url)} className="text-primary font-semibold cursor-pointer">{thread.Forum.title}</span>
				<span className="text-gray-500"> • <RelativeTime timestamp={thread.post_date} /></span>
			</div>
		</div>
	);
};

const ReviewSection = () => {
	const {i18n, t} = useTranslation();

	const {
		reviewPosts, hasMoreReviewPosts, loadMoreReviewPosts,
	} = useContext(HomeContext);

	if (reviewPosts && reviewPosts.length > 0) {
		return (
			<div>
				<div className="">
					<SectionHeader title={t('most_viewed')} icon={<FontAwesomeIcon icon={faComments} className="mr-2"/>} />
				</div>

				<div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
					{reviewPosts.map((thread, index) => (
						<ReviewItem key={index} thread={thread} />
					))}
				</div>

				{hasMoreReviewPosts && (
					<div className="text-center mt-4">
						<button onClick={loadMoreReviewPosts}
						        className="inline-block w-auto mx-auto px-2 py-1 bg-primary text-sm font-semibold text-white rounded transition hover:bg-red-900">
							{t('load_more')}
						</button>
					</div>
				)}
			</div>
		);
	}
};

export default ReviewSection;
