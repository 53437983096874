import {createContext, useContext} from "react";
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query";
import {API_BASE_URL, API_KEY} from "../config/config";
import axios from "axios";
import {AuthContext} from "./authContext";

const SidebarContext = createContext(null);

const queryClient = new QueryClient();

const fetchAds = async ({ queryKey }) => {
	const [_, {visitor}] = queryKey;
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_BASE_URL}/sidebar/ads`,
		headers: {
			'XF-Api-Key': API_KEY,
			'XF-Api-User': visitor.user_id,
		},
		withCredentials: true
	};
	const response = await axios.request(config);
	return response.data.ads;
};

const SidebarProvider = ({ children }) => {
	const {visitor} = useContext(AuthContext);

	const { data: sidebarAds } = useQuery({
		queryKey: ['sidebarAds', {visitor}],
		queryFn: fetchAds,
		enabled: !!visitor,
	});

	return (
		<SidebarContext.Provider
			value={{
				sidebarAds
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
}

const SidebarContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<SidebarProvider>{children}</SidebarProvider>
	</QueryClientProvider>
);

export {SidebarContext, SidebarContextProvider as SidebarProvider};